import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background: rgba(69, 74, 63, 0.5);
  backdrop-filter: blur(4px);
`;

export const Content = styled.div`
  width: 100%;
  max-width: 293px;
  border-radius: 14px;
  background: var(--main-color-bg-widgets);
  backdrop-filter: blur(50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  margin: 0px;
  padding: 20px 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  text-align: center;

  /* color: var(--main-color-text-title); */
  color: #232423;
`;

export const DescriptionBlock = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
`;

export const Description = styled.p`
  margin: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  /* color: var(--main-color-text-secondary); */
  color: #232423;
`;

export const Line = styled.p`
  height: 1px;
  background: var(--main-color-border-icon);
  width: 100%;
  margin: 0px;
`;
