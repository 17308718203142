import { ChangeEvent, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { InputNumber, Textarea } from "components";
import { CardInput } from "molecules/CardInput/CardInput";
import { Button } from "components/Button";
import { WarningAlert } from "components/WarningAlert/WarningAlert";
import CardSelect from "./CardSelect/CardSelect";
import { validateAmount } from "utils/validateAmount";
import {
  MAX_TRANSFER_REASON_LENGTH,
  MAX_TRANSFER_PAN_MAX_LENGTH,
} from "constants/limits";
import { TabVariants } from "store/slices/system/slice";
import { ClickableHeader } from "./ClickableHeader";
import { resetTransferErrorMessage } from "store/slices/transferSlice";

interface TransferFormProps {
  isMobile: boolean;
  activeCard: any;
  hasActiveCard: boolean;
  currencySign: string;
  amount: number | undefined;
  errorMessageAmount: string;
  isAmountExceedError: boolean;
  errorMessage: any;
  errorAmount: string;
  pan: string | undefined;
  reason: string | undefined;
  isCardError: boolean;
  isMessageError: boolean;
  dispatch: any;
  setErrorAmount: (value: string) => void;
  setTransferValues: (value: any) => void;
  transferValues: any;
  goToHandler: (tab: TabVariants) => void;
  continueHandler: () => void;
  isTransferValid: boolean;
  isErrorInFields: boolean;
  isLoading: boolean;
}

const TransferForm = ({
  isMobile,
  activeCard,
  hasActiveCard,
  currencySign,
  amount,
  errorMessageAmount,
  isAmountExceedError,
  errorMessage,
  errorAmount,
  pan,
  reason,
  isCardError,
  isMessageError,
  dispatch,
  setErrorAmount,
  setTransferValues,
  transferValues,
  goToHandler,
  continueHandler,
  isTransferValid,
  isErrorInFields,
  isLoading,
}: TransferFormProps) => {
  useEffect(() => {
    const originalBodyPosition = document.body.style.position;
    document.body.style.position = "static";

    return () => {
      document.body.style.position = originalBodyPosition;
    };
  }, []);

  return (
    <Box
      maxWidth={isMobile ? "auto" : "580px"}
      display="flex"
      flexDirection="column"
      boxSizing="border-box"
    >
      <ClickableHeader onBack={() => goToHandler(TabVariants.transfersHub)} />
      {!activeCard && (
        <Box
          sx={{
            position: "relative",
            "@media (max-width: 768px)": {
              width: "106%",
              left: "-16px",
            },
            "@media (max-width: 560px)": {
              width: "108%",
            },
            "@media (max-width: 420px)": {
              width: "110%",
            },
          }}
        >
          <WarningAlert title="У вас нет карт доступных для перевода" />
        </Box>
      )}

      <Box display="flex" mb="60px" mt={20} flexDirection="column" rowGap={12}>
        <InputNumber
          label="Сумма перевода"
          placeholder="Введите cумму"
          disabled={!hasActiveCard}
          suffix={` ${currencySign}`}
          value={amount}
          error={!!errorMessageAmount || isAmountExceedError}
          hint={isAmountExceedError ? errorMessage.title : ""}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (errorMessage.title && isAmountExceedError) {
              dispatch(resetTransferErrorMessage());
            }
            setErrorAmount("");
            setTransferValues({
              ...transferValues,
              amount: validateAmount(event.target.value),
            });
          }}
        />
        <div>
          <CardSelect activeCard={activeCard} />
          {(errorMessageAmount || errorAmount) && (
            <Typography
              sx={{
                color: "var(--error-color-icon)",
                lineHeight: "20px",
              }}
            >
              {errorAmount ? errorAmount : errorMessageAmount}
            </Typography>
          )}
        </div>
        <CardInput
          transfer
          name="cdNumber"
          label="На карту"
          sublabel="Возможен только на карты МИР"
          labelColor="var(--main-color-text-title)"
          subLabelColor="var(--main-color-text-secondary)"
          value={pan}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (errorMessage.title && isCardError) {
              dispatch(resetTransferErrorMessage());
            }
            setTransferValues({
              ...transferValues,
              pan:
                event.target.value.length <= MAX_TRANSFER_PAN_MAX_LENGTH
                  ? event.target.value
                  : pan,
            });
          }}
          disabled={!hasActiveCard}
          mb={0}
          errorMessage={isCardError ? errorMessage.title : ""}
          error={isCardError}
          showCustomKeyboard
        />
        <Textarea
          label="Сообщение получателю (1000 символов)"
          value={reason}
          disabled={!hasActiveCard}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            if (errorMessage.title && isMessageError) {
              dispatch(resetTransferErrorMessage());
            }
            setTransferValues({
              ...transferValues,
              reason:
                event.target.value.length <= MAX_TRANSFER_REASON_LENGTH
                  ? event.target.value
                  : event.target.value.substr(0, MAX_TRANSFER_REASON_LENGTH),
            });
          }}
          inputColor="var(--main-color-text-title)"
          hint={isMessageError ? errorMessage.title : ""}
          error={isMessageError}
        />
      </Box>
      <Box>
        <Button
          variant="primary"
          onClick={continueHandler}
          disabled={!isTransferValid || isErrorInFields || !hasActiveCard}
          isLoading={isLoading}
          title="Продолжить"
        />
      </Box>
    </Box>
  );
};

export default TransferForm;
