import { useIsMobile } from "hooks/useIsMobile";
import { StickyContainer } from "molecules/StickyContainer";
import { Container, DownloadIconWrapper, Title } from "./components";
import { IconDownload } from "atoms/IconDownload/IconDownload";
import { useSelector } from "store";
import { sectionTitles } from "constants/sectionTitles";
import { TabVariants } from "store/slices/system";
import { HistoryTabs } from "../HistoryTabs";

interface HistoryHeaderProps {
  handleDownload?: () => void;
  reset: () => void;
}

export const HistoryHeader = ({
  handleDownload,
  reset,
}: HistoryHeaderProps) => {
  const { isMobile } = useIsMobile();
  const { isReportLoading } = useSelector((state) => state.history);

  const downloadBtn = (
    <DownloadIconWrapper disabled={isReportLoading} onClick={handleDownload}>
      <IconDownload width={20} height={20} />
    </DownloadIconWrapper>
  );

  if (isMobile) {
    return (
      <StickyContainer
        containerMargin={-8}
        sx={{
          padding: "30px 16px 8px",
          backgroundColor: "var(--main-color-white)",
        }}
      >
        <Container>
          <Title>{sectionTitles[TabVariants.history].mobile}</Title>
          {downloadBtn}
        </Container>
        <HistoryTabs reset={reset} />
      </StickyContainer>
    );
  }

  return (
    <>
      <Title>{sectionTitles[TabVariants.history].desktop}</Title>
      <Container>
        <HistoryTabs reset={reset} />
        {downloadBtn}
      </Container>
    </>
  );
};
