import { OperationCheck } from "components/OperationCheck";
import { useSelector } from "store";

export const TransferConfirmed = () => {
  const { transfer } = useSelector((state) => state);

  if (!transfer?.confirmedTransfer) return null;

  return <OperationCheck confirmedTransfer={transfer.confirmedTransfer} />;
};
