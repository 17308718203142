import { useEffect, useState } from "react";
import {
  CategoryItem,
  CategoryItemColor,
  CategoryItemSum,
  CategoryItemText,
  CategoryItemsContainer,
  Container,
  HeaderBlock,
  HeaderLeft,
  HeaderMonth,
  HeaderRight,
  Line,
  LinesContainer,
  MoreButton,
  Sum,
  DateWrapper,
  HeaderSubtitleWrapper,
  Expense,
  ArrowsBlock,
  HeaderWrapper,
  ArrowWrapper,
  CustomArrowWrapper,
} from "./components";
import { useIsMobile } from "hooks/useIsMobile";
import { TabVariants, systemActions } from "store/slices/system";
import {
  ERROR_MESSAGE_DATEFROM_INTERVAL,
  ERROR_MESSAGE_DATEFROM_TODAY,
  ERROR_MESSAGE_DATETO_INTERVAL,
  ERROR_MESSAGE_DATETO_TODAY,
  OperationDate,
  ValueDateType,
} from "../SearchFilters/components";
import { DatePicker, SkeletonContainer } from "components";
import { parseDate } from "utils";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  HISTORY_TABS,
  getCategoryExpenseLight,
  getListReportPdf,
  setActiveCategory,
  setActiveTab,
  setDate,
  setShowMobileFinancialAnalysisPage,
} from "store/slices/historySlice";
import { useDispatch, useSelector } from "store";
import { getEndOfDay } from "utils/getEndOfDay";
import { DownloadIconWrapper } from "../HistoryTabs/components";
import { StatementView } from "api/account";
import { FileType, downloadFile } from "utils/downloadFile";
import moment from "moment";
import { MONTHS } from "constants/months";
import { isFullMonthRange } from "utils/dateUtils";
import { IconCalendar } from "atoms/IconCalendar/IconCalendar";
import { IconDownload } from "atoms/IconDownload/IconDownload";
import { IconChevronLeft } from "atoms/IconChevronLeft/IconChevronLeft";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { Button } from "components/Button";

interface FinancialAnalysisWidgetProps {
  isPreview: boolean;
  isDisabled?: boolean;
  changeDateArrowHandler?: (value: ValueDateType) => void;
}

const COLORS = [
  "var(--secondary-color-5)",
  "var(--secondary-color-3)",
  "var(--secondary-color-6)",
  "var(--secondary-color-2)",
  "var(--secondary-color-4)",
];
const EMPTY_COLOR = "#EAEAEA";

export const FinancialAnalysisWidget = ({
  isPreview,
  isDisabled,
  changeDateArrowHandler,
}: FinancialAnalysisWidgetProps) => {
  const {
    cards,
    system,
    history: {
      categoriesData,
      isCategoriesDataLoading,
      activeTab,
      showMobileFinancialAnalysisPage,
      date,
      activeCategoryId,
      date: { from, to },
    },
  } = useSelector((state) => state);

  const allSum = categoriesData?.length
    ? categoriesData
        .map((item) => item.sum)
        .reduce((accumulator, currentValue) => {
          if (accumulator && currentValue) {
            return accumulator + currentValue;
          }
        })
    : 0;

  const dateNow = new Date();
  const monthNumber = dateNow.getMonth();
  const yearNumber = dateNow.getFullYear();
  const currentMonth = dateNow.toLocaleString("ru", { month: "long" });
  const currentYear = dateNow.toLocaleString("ru", { year: "numeric" });
  const firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

  const isFullMonth = isFullMonthRange(new Date(from), new Date(to));

  const initialState = {
    from: firstDayMonth.toISOString(),
    to: getEndOfDay(dateNow),
    operationFilterType: undefined,
    page: 0,
    acctId: cards.account.accountNumber,
    search: "",
    fromAmount: undefined,
    toAmount: undefined,
    cardId: "",
    size: 10,
  };

  const [sum, setSum] = useState<number>(0);
  const [isDisabledDate, setIsDisabledDate] = useState<boolean>(true);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [intermediateDate, setIntermediateDate] = useState(date);
  const { isMobile } = useIsMobile();
  const isMainPageDesktop = isPreview && !isMobile;
  const dispatch = useDispatch();

  const handleClickOutside = () => {
    setIsOpenDate(false);
  };

  const rootElement = useOutsideClick(handleClickOutside);

  const errorMessageFrom =
    intermediateDate.from > intermediateDate.to
      ? ERROR_MESSAGE_DATEFROM_INTERVAL
      : intermediateDate.from > new Date().toISOString()
      ? ERROR_MESSAGE_DATEFROM_TODAY
      : "";

  const errorMessageTo =
    intermediateDate.to < intermediateDate.from
      ? ERROR_MESSAGE_DATETO_INTERVAL
      : intermediateDate.to > getEndOfDay(dateNow)
      ? ERROR_MESSAGE_DATETO_TODAY
      : "";

  let fromText = parseDate(date.from);
  const toText = parseDate(date.to);

  const fromTextYear = fromText.split(".").at(-1);
  const toTextYear = toText.split(".").at(-1);

  if (fromTextYear === toTextYear) {
    const fromTextArr = fromText.split(".");
    fromTextArr.pop();
    fromText = fromTextArr.join(".");
  }

  const customDate = date.from
    ? date.to
      ? `${fromText} - ${toText}`
      : `с ${fromText}`
    : date.to && toText !== parseDate(new Date().toISOString())
    ? `по ${toText}`
    : "";

  const fromFormat = new Date(firstDayMonth.toISOString()).toLocaleDateString(
    "ru"
  );
  const toFormat = new Date(getEndOfDay(dateNow)).toLocaleDateString("ru");
  const fromFormat1 = new Date(date.from).toLocaleDateString("ru");
  const toFormat1 = new Date(date.to).toLocaleDateString("ru");

  const defaultDate = `${
    currentMonth[0].toUpperCase() + currentMonth.slice(1)
  } ${currentYear}`;

  const isDefaultDate = fromFormat === fromFormat1 && toFormat === toFormat1;

  const dateNumbers = toText.split(".").map((number) => Number(number));
  const monthDate = `${MONTHS[dateNumbers[1] - 1]} ${dateNumbers[2]}`;

  const isDisabledRightArrow =
    currentMonth.toLowerCase() === MONTHS[dateNumbers[1] - 1].toLowerCase() &&
    Number(currentYear) === Number(dateNumbers[2]);

  const changeDateLeft = () => {
    const fromDate = moment(from).subtract(1, "month").toDate().toISOString();
    const date = moment(from).subtract(1, "day").toDate();

    date.setHours(23, 59, 59, 999);
    const toDate = date.toISOString();
    dispatch(setDate({ from: fromDate, to: toDate }));
    if (system.activeTab === "history" && isMobile && changeDateArrowHandler) {
      changeDateArrowHandler({ from: fromDate, to: toDate });
    }
  };

  const changeDateRight = () => {
    if (isDisabledRightArrow) return;
    const fromDate = moment(from).add(1, "month").toDate().toISOString();
    const date = moment(from).add(2, "month").subtract(1, "day").toDate();

    if (monthNumber === date.getMonth() && yearNumber === date.getFullYear()) {
      date.setDate(dateNow.getDate());
    }

    date.setHours(23, 59, 59, 0);
    const toDate = date.toISOString();
    dispatch(setDate({ from: fromDate, to: toDate }));
    if (system.activeTab === "history" && isMobile && changeDateArrowHandler) {
      changeDateArrowHandler({ from: fromDate, to: toDate });
    }
  };

  const downloadReportPdf = () => {
    const operationViewType = {
      [HISTORY_TABS.ALL_OPERATIONS]: StatementView.All,
      [HISTORY_TABS.EXPENSES]: StatementView.Expense,
      [HISTORY_TABS.INCOMES]: StatementView.Income,
    };

    dispatch(
      getListReportPdf({
        to,
        from,
        acctId: cards.account.accountNumber,
        categoryIds: activeCategoryId ? [activeCategoryId] : undefined,
        operationViewType: operationViewType[activeTab],
      })
    )
      .unwrap()
      .then((res) => downloadFile(res, "report.pdf", FileType.PDF));
  };

  const categoryClick = (categoryName: number, categorySum: number) => {
    if (isPreview) return;
    if (activeCategoryId === categoryName) {
      dispatch(setActiveCategory(null));
      setSum(allSum || 0);
    } else {
      dispatch(setActiveCategory(categoryName));
      setSum(categorySum);
    }
  };

  const widgetClick = () => {
    if (!isPreview || isDisabled) return;
    if (!isMainPageDesktop) {
      dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
      dispatch(setShowMobileFinancialAnalysisPage(true));
    }
  };

  const submitPeriod = () => {
    dispatch(setDate(intermediateDate));
    if (showMobileFinancialAnalysisPage) {
      changeDateArrowHandler && changeDateArrowHandler(intermediateDate);
    }
    setIsOpenDate(false);
  };

  const moreBtnHandler = () => {
    dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
    if (isMobile) {
      dispatch(setShowMobileFinancialAnalysisPage(true));
    } else {
      dispatch(systemActions.setActiveTab({ tab: TabVariants.history }));
    }
  };

  useEffect(() => {
    if (
      initialState.acctId &&
      activeTab === HISTORY_TABS.EXPENSES &&
      system.activeTab === "main" &&
      !showMobileFinancialAnalysisPage
    ) {
      dispatch(getCategoryExpenseLight(initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState.acctId, system.activeTab]);

  useEffect(() => {
    if (allSum) {
      setSum(allSum);
    } else {
      setSum(0);
    }
    if (activeCategoryId) {
      const activeCategory = categoriesData?.filter(
        (category) => category.id === activeCategoryId
      )[0];
      if (activeCategory && activeCategory.sum) {
        setSum(activeCategory.sum);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSum, activeCategoryId]);

  useEffect(() => {
    setIntermediateDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.from, date.to]);

  return (
    <SkeletonContainer
      width="100%"
      height={169}
      isLoading={isCategoriesDataLoading}
      marginBottom={20}
    >
      <Container
        withPadding={isPreview}
        withMarginBottom={isPreview}
        background={isPreview ? "var(--main-color-bg-widgets)" : "transparent"}
        onClick={widgetClick}
      >
        <HeaderBlock>
          {isPreview && isDisabled && (
            <>
              <OperationDate
                value={{ from, to }}
                appliedValues={intermediateDate}
                setAppliedValues={setIntermediateDate}
                onChange={(value) => dispatch(setDate({ from, to, ...value }))}
                changeDateArrowHandler={
                  changeDateArrowHandler ? changeDateArrowHandler : () => {}
                }
                isMobile
                isHideArrows
              />
              {isFullMonth && (
                <CustomArrowWrapper>
                  <ArrowWrapper onClick={changeDateLeft}>
                    <IconChevronLeft />
                  </ArrowWrapper>
                  <ArrowWrapper onClick={changeDateRight}>
                    <IconChevronRight />
                  </ArrowWrapper>
                </CustomArrowWrapper>
              )}
            </>
          )}
          {isPreview && !isDisabled && (
            <>
              <HeaderLeft>Финансовая статистика</HeaderLeft>
              <HeaderSubtitleWrapper>
                <Expense>Расходы</Expense>
                <HeaderRight onClick={() => setIsOpenDate(!isOpenDate)}>
                  <IconCalendar width={20} height={20} color="#B5B5B5" />
                  <HeaderMonth>{defaultDate}</HeaderMonth>
                </HeaderRight>
              </HeaderSubtitleWrapper>
            </>
          )}
          {isMobile && !isPreview && (
            <HeaderWrapper>
              <HeaderRight>
                <HeaderRight
                  onClick={() => setIsOpenDate(!isOpenDate)}
                  style={{
                    width: isFullMonth ? "153px" : "auto",
                  }}
                >
                  <IconCalendar width={20} height={20} color="#739B67" />
                  <HeaderMonth style={{ color: "#739B67" }}>
                    {isDefaultDate
                      ? defaultDate
                      : isFullMonth
                      ? monthDate
                      : customDate}
                  </HeaderMonth>
                </HeaderRight>
                {isFullMonth && (
                  <ArrowsBlock style={{ marginLeft: "15px" }}>
                    <div style={{ cursor: "pointer" }} onClick={changeDateLeft}>
                      <IconChevronLeft />
                    </div>

                    <div
                      style={{
                        cursor: !isDisabledRightArrow ? "pointer" : "auto",
                      }}
                      onClick={changeDateRight}
                    >
                      <IconChevronRight
                        color={isDisabledRightArrow ? "#8B8C88" : undefined}
                      />
                    </div>
                  </ArrowsBlock>
                )}
              </HeaderRight>
              {isOpenDate && (
                <DateWrapper ref={rootElement}>
                  <DatePicker
                    label="Начало периода"
                    placeholder="00.00.0000"
                    value={
                      intermediateDate.from
                        ? new Date(intermediateDate.from)
                        : null
                    }
                    error={!!errorMessageFrom}
                    hint={errorMessageFrom}
                    onChange={(from: Date | null) => {
                      setIsDisabledDate(false);
                      setIntermediateDate((prev) => ({
                        ...prev,
                        from: from?.toISOString()!,
                      }));
                    }}
                  />
                  <DatePicker
                    label="Конец периода"
                    placeholder="00.00.0000"
                    value={
                      intermediateDate.to ? new Date(intermediateDate.to) : null
                    }
                    error={!!errorMessageTo}
                    hint={errorMessageTo}
                    onChange={(to: Date | null) => {
                      setIsDisabledDate(false);
                      setIntermediateDate((prev) => ({
                        ...prev,
                        to: to?.toISOString()!,
                      }));
                    }}
                  />
                  <Button
                    variant="primary"
                    onClick={submitPeriod}
                    disabled={
                      !!errorMessageFrom || !!errorMessageTo || isDisabledDate
                    }
                    title="Применить фильтр"
                  />
                </DateWrapper>
              )}
              <DownloadIconWrapper
                disabled={false}
                onClick={downloadReportPdf}
                style={{ marginRight: 0 }}
              >
                <IconDownload />
              </DownloadIconWrapper>
            </HeaderWrapper>
          )}
        </HeaderBlock>
        {!isCategoriesDataLoading && (
          <>
            <Sum>{sum.toLocaleString()} ₽</Sum>
            <LinesContainer>
              {categoriesData?.length ? (
                categoriesData?.map((item, index) => {
                  return (
                    <Line
                      cursor={!isMainPageDesktop}
                      onClick={() => categoryClick(item.id || 0, item.sum || 0)}
                      key={item.name}
                      background={
                        activeCategoryId === null ||
                        activeCategoryId === item.id
                          ? COLORS[index]
                          : EMPTY_COLOR
                      }
                      percent={`${item.percent}%`}
                    />
                  );
                })
              ) : (
                <Line
                  cursor={false}
                  onClick={() => {}}
                  background={EMPTY_COLOR}
                  percent="100%"
                />
              )}
            </LinesContainer>
            {!isPreview && categoriesData?.length ? (
              <CategoryItemsContainer>
                {categoriesData?.map((item, index) => {
                  return (
                    <CategoryItem
                      key={item.name}
                      onClick={() => categoryClick(item.id || 0, item.sum || 0)}
                      opacity={
                        activeCategoryId === null ||
                        activeCategoryId === item.id
                          ? 1
                          : 0.5
                      }
                    >
                      <CategoryItemColor background={COLORS[index]} />
                      <CategoryItemText>{item.name}</CategoryItemText>
                      {activeCategoryId === item.id && (
                        <CategoryItemSum>
                          {item.sum && item.sum.toLocaleString()} ₽
                        </CategoryItemSum>
                      )}
                    </CategoryItem>
                  );
                })}
              </CategoryItemsContainer>
            ) : null}
            {isPreview && (
              <MoreButton onClick={moreBtnHandler}>Подробнее</MoreButton>
            )}
          </>
        )}
      </Container>
    </SkeletonContainer>
  );
};
