import { Box, Grid, Typography } from "@mui/material";
import { Button } from "components/Button";
import { useEffect, useState } from "react";
import { IconTopUp } from "atoms/IconTopUp";
import { IconWriteOff } from "atoms/IconWriteOff";
import rostelecom from "./assets/rostelecom.png";
import nike from "./assets/nike.png";
import styles from "./TransactionsHistory.module.css";
import { Link } from "react-router-dom";
import { ViewOperationHistoryModal } from "organisms/Limits/ViewOperationHistoryModal/ViewOperationHistoryModal";
import { makeStyles } from "@mui/styles";
import cn from "classnames";
import { useSelector } from "store";

const operationsDataDebit = [
  {
    date: "Сегодня",
    data: [
      {
        name: "Ростелеком",
        service: "Услуги",
        img: rostelecom,
        amount: "– 1 200 ₽",
        status: "output",
        color: "gray.b800",
      },
      {
        name: "∙∙ 6578",
        service: "Пополнение",
        img: <IconTopUp />,
        amount: "+ 1 200 ₽",
        status: "input",
        color: "green.b500",
      },
      {
        name: "∙∙ 6578",
        service: "Перевод на карту",
        img: <IconWriteOff />,
        amount: "– 1 200 ₽",
        status: "outputInside",
        color: "blueGray.b500",
      },
    ],
  },
  {
    date: "Вчера",
    data: [
      {
        name: "Nike Россия",
        service: "Товары",
        img: nike,
        amount: "– 7 800 ₽",
        status: "output",
        color: "gray.b800",
      },
    ],
  },
];

const useStyles = makeStyles(() => ({
  wrap: {
    transition: ".2s",
    borderRadius: 20,
    border: "8px solid #fff",
    cursor: "pointer",
    "&:hover": {
      transition: ".2s",
      boxShadow: "0px 4px 20px 4px var(--main-color-box-shadow-4)",
    },
  },
}));

const DebetCardItem = ({ service, status, img, name, color, amount }) => {
  const classes = useStyles();
  return (
    <Box key={service} className={cn(styles.transaction, classes.wrap)}>
      {status === "output" ? <Box component="img" src={img} /> : img}
      <Box className={styles.transactionMainInfo}>
        <Typography
          variant="text_5"
          color="gray.b800"
          fontWeight="500"
          className={styles.transactionPurpose}
        >
          {name}
        </Typography>
        <Typography
          variant="text_3"
          color="gray.b400"
          fontWeight="400"
          className={styles.transactionType}
        >
          {service}
        </Typography>
      </Box>
      <Typography
        variant="text_5"
        color={color}
        fontWeight="500"
        whiteSpace="nowrap"
        className={styles.transactionAmount}
      >
        {amount}
      </Typography>
    </Box>
  );
};

const DebetCard = ({ searchData, handleHaveSearch, handleClickOpen }) => {
  useEffect(() => {
    if (searchData) {
      operationsDataDebit
        .map(({ data }) =>
          data.filter((data) => data.name.toLowerCase().includes(searchData))
        )
        .filter((data) => data.length !== 0).length !== 0
        ? handleHaveSearch(true)
        : handleHaveSearch(false);
      return () => handleHaveSearch(true);
    }
  }, [searchData, handleHaveSearch]);

  return (
    <>
      {operationsDataDebit.map(({ date, data }) => (
        <Box
          key={date}
          className={styles.dateContainer}
          onClick={() => handleClickOpen()}
        >
          <Typography variant="text_3" color="gray.b400" fontWeight="500">
            {searchData
              ? data.filter((data) =>
                  data.name.toLowerCase().includes(searchData)
                ).length !== 0 && date
              : date}
          </Typography>
          {searchData
            ? data
                .filter((data) => data.name.toLowerCase().includes(searchData))
                .map(({ name, service, img, amount, status, color }) => (
                  <DebetCardItem
                    key={service}
                    name={name}
                    service={service}
                    img={img}
                    amount={amount}
                    status={status}
                    color={color}
                  />
                ))
            : data.map(({ name, service, img, amount, status, color }) => (
                <DebetCardItem
                  key={service}
                  name={name}
                  service={service}
                  img={img}
                  amount={amount}
                  status={status}
                  color={color}
                />
              ))}
        </Box>
      ))}
    </>
  );
};

const operationsDataSaving = [
  {
    date: "10.02.2022",
    data: [
      {
        name: "∙∙ 6578",
        service: "Пополнение",
        img: <IconTopUp />,
        amount: "+ 2 000 ₽",
        color: "green.b500",
      },
    ],
  },
  {
    date: "27.01.2022",
    data: [
      {
        name: "∙∙ 6578",
        service: "Пополнение",
        img: <IconTopUp />,
        amount: "+ 6 000 ₽",
        color: "green.b500",
      },
    ],
  },
];

const SavingsCardItem = ({ service, img, name, amount, color }) => {
  const classes = useStyles();
  return (
    <Box key={service} className={cn(styles.transaction, classes.wrap)}>
      {img}
      <Box className={styles.transactionMainInfo}>
        <Typography
          variant="text_5"
          color="gray.b800"
          fontWeight="500"
          className={styles.transactionPurpose}
        >
          {name}
        </Typography>
        <Typography
          variant="text_3"
          color="gray.b400"
          fontWeight="400"
          className={styles.transactionType}
        >
          {service}
        </Typography>
      </Box>
      <Typography
        variant="text_5"
        color={color}
        fontWeight="500"
        whiteSpace="nowrap"
        className={styles.transactionAmount}
      >
        {amount}
      </Typography>
    </Box>
  );
};

const SavingsCard = ({ searchData, handleHaveSearch }) => {
  useEffect(() => {
    if (searchData) {
      operationsDataSaving
        .map(({ data }) =>
          data.filter((data) => data.name.toLowerCase().includes(searchData))
        )
        .filter((data) => data.length !== 0).length !== 0
        ? handleHaveSearch(true)
        : handleHaveSearch(false);
      return () => handleHaveSearch(true);
    }
  }, [searchData, handleHaveSearch]);

  return (
    <>
      {operationsDataSaving.map(({ date, data }) => (
        <Box key={date} className={styles.dateContainer}>
          <Typography variant="text_3" color="gray.b400" fontWeight="500">
            {searchData
              ? data.filter((data) =>
                  data.name.toLowerCase().includes(searchData)
                ).length !== 0 && date
              : date}
          </Typography>
          {searchData
            ? data
                .filter((data) => data.name.toLowerCase().includes(searchData))
                .map(({ name, service, img, amount, color }) => (
                  <SavingsCardItem
                    key={service}
                    name={name}
                    service={service}
                    img={img}
                    amount={amount}
                    color={color}
                  />
                ))
            : data.map(({ name, service, img, amount, color }) => (
                <SavingsCardItem
                  key={service}
                  name={name}
                  service={service}
                  img={img}
                  amount={amount}
                  color={color}
                />
              ))}
        </Box>
      ))}
    </>
  );
};

export function TransactionsHistory({ isHeading = null, title, searchData }) {
  const activeCard = useSelector((state) =>
    state?.cards.cards.find(({ isActive }) => isActive)
  );
  const [haveSearch, setHaveSearch] = useState(true);

  const handleHaveSearch = (value) => {
    setHaveSearch(value);
  };

  const [isOpen, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid xl={8}>
      {isHeading && (
        <Typography
          variant="text_7"
          color="gray.b900"
          fontWeight="600"
          mb="20px"
          component="div"
        >
          История операций
        </Typography>
      )}
      <Box className={styles.content}>
        <Box className={styles.datesContainer}>
          {activeCard.type === "visa" ? (
            <DebetCard
              handleClickOpen={handleClickOpen}
              searchData={searchData}
              handleHaveSearch={handleHaveSearch}
            />
          ) : (
            <SavingsCard
              searchData={searchData}
              handleHaveSearch={handleHaveSearch}
            />
          )}
        </Box>
        {haveSearch ? (
          <Link to="/history">
            <Button variant="secondary" title={title ? title : "Вся история"} />
          </Link>
        ) : (
          <Typography variant="text_5" color="gray.b900" fontWeight="500">
            Результатов не найдено
          </Typography>
        )}
      </Box>
      <ViewOperationHistoryModal isOpen={isOpen} handleClose={handleClose} />
    </Grid>
  );
}
