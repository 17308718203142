import { Typography, Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Screens } from "./models";
import { useCounter } from "../../../../hooks";
import { CodeInput } from "components/CodeInput";
import { getSecondsPluralForms } from "utils";
import {
  updateCardLimitConfirm,
  setNewLimit,
} from "../../../../store/slices/limitsSlice";
import { CardLimitConfirmResponse } from "../../../../api/account";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { retryOneTimePassword } from "store/slices/auth";
import { Button } from "components/Button";
import { useDispatch, useSelector } from "store";
import styles from "./styles.module.css";

type Props = {
  onClick: (nextPage: Screens) => void;
  nextScreen: Screens;
};

export const Confirmation = ({ onClick, nextScreen }: Props) => {
  const dispatch = useDispatch();
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const [error, setError] = useState("");
  const { isMobile } = useIsMobile();
  const { control, watch } = useForm<{ code: string }>({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });
  const code = watch("code");

  const {
    limits: { limitToken },
  } = useSelector((state) => state);

  const handleResendCode = () => {
    dispatch(retryOneTimePassword(limitToken));
    restartCounter();
  };

  const handleClick = () => {
    dispatch(
      updateCardLimitConfirm({
        updateLimitToken: limitToken,
        verifyCode: code,
      })
    )
      .unwrap()
      .then((res: Array<CardLimitConfirmResponse>) => {
        dispatch(setNewLimit(res[0]));
        onClick(nextScreen);
      })
      .catch(() => {
        setError("Ошибка: Неверный код. Попробуйте снова");
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        width={isMobile ? 343 : 394}
        height="100%"
        margin="0 auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" gap={4} width="100%">
          <p className={styles.description}>Код из SMS</p>
          {error && <p className={styles.error}>{error}</p>}
          <Controller
            control={control}
            name="code"
            defaultValue={code}
            render={({ field }) => (
              <CodeInput
                length={4}
                setCode={field.onChange}
                error={Boolean(error)}
                clearError={() => setError("")}
              />
            )}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap={20}
            mt={66}
            width="100%"
          >
            <Button
              disabled={code.length < 4}
              variant="primary"
              onClick={handleClick}
              title="Продолжить"
            />
            {count === 0 ? (
              <Button
                onClick={handleResendCode}
                variant="secondary"
                title="Отправить код повторно"
              />
            ) : (
              <Box textAlign="center" minHeight={52}>
                <Typography variant="text_5" color="gray.b400">
                  Вы сможете отправить код повторно через{" "}
                  <Typography variant="text_5" color="blue.b400">
                    {count} {secondsWord}.
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
