import { Modal } from "molecules/Modal/Modal";
import { AddLimit } from "./AddLimit";
import { Confirmation } from "./Confiramtion";
import { Screens } from "./models";
import { Success } from "./Success";
import { UnSuccess } from "./UnSuccess";
import { CardLimitConfirmResponse } from "../../../../api/account";
import { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { clearError } from "store/slices/limitsSlice";
import { authActions } from "store/slices/auth";
import { Typography } from "@mui/material";
import { CARD_ACTION, setScreen } from "store/slices/cardsSlice";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  screen: Screens;
  onChangeScreens: (screen: Screens) => void;
  limitItem: CardLimitConfirmResponse;
};

export const LimitModal = ({
  isOpen,
  onClose,
  screen,
  onChangeScreens,
  limitItem,
}: Props) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.limits);

  const screens: Record<Screens, React.ReactNode> = {
    initial: (
      <AddLimit
        onClick={onChangeScreens}
        nextScreen="confirmation"
        limitItem={limitItem}
      />
    ),
    confirmation: (
      <Confirmation onClick={onChangeScreens} nextScreen="success" />
    ),
    success: <Success onClick={onClose} limitItem={limitItem} />,
    unsuccess: (
      <UnSuccess
        onClick={() => {
          onClose();
          dispatch(clearError());
          dispatch(setScreen(CARD_ACTION.INITIAL));
        }}
        payload={error}
        buttonTitle="Понятно"
      />
    ),
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(authActions.setIsBurgerActive(true));
    } else {
      dispatch(authActions.setIsBurgerActive(false));
    }
  }, [isOpen, dispatch]);

  return (
    <Modal
      center
      title={
        screen === "confirmation" && (
          <div>
            <Typography
              sx={{
                color: "#454A3F",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "24px",
                textAlign: "center",
              }}
            >
              Подтвердите
            </Typography>
            <Typography
              sx={{
                color: "#454A3F",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "24px",
                textAlign: "center",
              }}
            >
              изменение лимита
            </Typography>
          </div>
        )
      }
      isOpen={isOpen}
      onClose={onClose}
      buttonVisible
      isProfile
      callback={onClose}
    >
      {screens[screen]}
    </Modal>
  );
};
