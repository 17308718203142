import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import { CardDto } from "api/account";
import {
  IconCreditBlocked,
  IconCreditCard,
  IconCreditCardClosed,
  IconCreditCardFrozen,
} from "atoms/IconCards/IconCards";
import { CARD_STATUS } from "components/CardsInfo/model";
import { Container, Content } from "./components";

interface Props {
  isOpen: boolean;
  handler?: () => void;
  cards: CardDto[];
  onClick: (card: CardDto, index: number) => void;
  onClose: () => void;
}

export const CardsBottomSheet = ({
  isOpen,
  cards,
  onClick,
  onClose,
}: Props) => {
  if (!isOpen) return null;

  return (
    <Modal open={isOpen} onClick={onClose}>
      <Container>
        <Content>
          {cards?.map((cardItem: any, index: number) => {
            const cardIsClosed = cardItem.status === CARD_STATUS.Closed;
            const cardIsBlocked = CARD_STATUS.Blocked.includes(cardItem.status);
            const cardIsFrozen =
              cardItem.status === CARD_STATUS.Temporary ||
              cardItem.status === CARD_STATUS.Referral;

            return (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    left: "50%",
                    top: "12px",
                    width: "32px",
                    height: "4px",
                    borderRadius: "100px",
                    opacity: 0.4,
                    background: "#79747E",
                    transform: "translateX(-50%)",
                  }}
                />
                <Box
                  key={index}
                  sx={{
                    cursor:
                      cardIsClosed || cardIsBlocked || cardIsFrozen
                        ? "not-allowed"
                        : "pointer",
                    display: "flex",
                    borderBottom:
                      index !== cards.length - 1
                        ? "1px solid #E9E9E9"
                        : "1px solid transparent",
                    padding: "16px 20px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity:
                      cardIsClosed || cardIsBlocked || cardIsFrozen ? 0.5 : 1,
                    ":hover": {
                      backgroundColor: "var(--brand-color-hover)",
                    },
                  }}
                  onClick={() => {
                    if (!(cardIsClosed || cardIsBlocked || cardIsFrozen)) {
                      onClick(cardItem, index);
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "20px",
                        justifyContent: "center",
                      }}
                    >
                      {[
                        "ACTIVE",
                        "VIP",
                        "DECLARED",
                        "RESTRICTED",
                        "OPEN_DOMESTIC",
                        "EXPIRED",
                      ].includes(cardItem.status) && (
                        <IconCreditCard size={20} />
                      )}
                      {cardIsFrozen && <IconCreditCardFrozen size={20} />}
                      {cardIsBlocked && <IconCreditBlocked />}
                      {cardIsClosed && <IconCreditCardClosed />}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "4px",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          color: "var(--main-color-text-title)",
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "20px",
                        }}
                      >
                        {cardItem.paymentNetwork
                          ? cardItem.paymentNetwork === "MIR"
                            ? "МИР"
                            : cardItem.paymentNetwork
                          : "МИР"}
                      </Typography>
                      <Box
                        sx={{
                          width: "5px",
                          height: "5px",
                          borderRadius: "100px",
                          backgroundColor: "var(--main-color-text-secondary)",
                        }}
                      />

                      <Typography
                        noWrap
                        sx={{
                          color: "var(--main-color-text-secondary)",
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "20px",
                        }}
                      >
                        {cardItem.cardPan?.slice(-4)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })}
        </Content>
      </Container>
    </Modal>
  );
};
