import React from "react";
import { Box } from "@mui/material";
import { CardDto } from "api/account";
import { useOutsideClick } from "hooks/useOutsideClick";
import { CardSelectItem } from "./CardSelectItem";

interface CardSelectModalProps {
  cards: CardDto[];
  mainCard: CardDto;
  isOpen: boolean;
  onClose?: () => void;
  onClick: (card: CardDto, index: number) => void;
}

const CARD_HEIGHT = 48;
const CONTAINER_PADDING = 20;

const CardSelectModal: React.FC<CardSelectModalProps> = ({
  cards,
  onClick,
  isOpen,
  onClose = () => {},
  mainCard,
}) => {
  const modalHeight =
    cards.length > 1 ? cards.length * CARD_HEIGHT + CONTAINER_PADDING : 0;

  const divFocus = useOutsideClick(onClose);

  return (
    <Box
      ref={divFocus}
      width="100%"
      display={isOpen ? "flex" : "none"}
      flexDirection="column"
      sx={{
        backgroundColor: "#fff",
        padding: "10px 0",
        borderRadius: 13,
        boxShadow: "0px 2px 10px var(--main-color-secondary-box-shadow)",
        animation: "fadeIn 0.2s ease-in-out",
        opacity: isOpen ? 1 : 0,
        transition: "0.5s",
        height: isOpen ? modalHeight : 0,
        position: "absolute",
        top: "60px",
        left: 0,
        zIndex: 1000,
        boxSizing: "border-box",
      }}
    >
      {cards.map((card, index) => (
        <CardSelectItem
          key={card.cardId}
          card={card}
          onClick={() => onClick(card, index)}
          mainCard={mainCard}
        />
      ))}
    </Box>
  );
};

export default CardSelectModal;
