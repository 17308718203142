import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import {
  setChosenCardIndex,
  CARD_ACTION,
  setScreen,
} from "store/slices/cardsSlice";
import { Card } from "./Card";
import { useDispatch } from "store";

const CardInfoItemContainer = styled(Box)({
  display: "flex",
  gap: "5px",
  alignItems: "center",
  cursor: "pointer",
  flexWrap: "wrap",
});

export const ConnectedCards = ({ cards }: { cards: any }) => {
  const dispatch = useDispatch();

  const openCard = (index: number) => {
    dispatch(setScreen(CARD_ACTION.INITIAL));
    dispatch(setChosenCardIndex(index));
  };

  return (
    <Box mt={18}>
      <Typography
        sx={{
          color: "#454A3F",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
        mb={14}
      >
        Привязанные карты
      </Typography>
      <CardInfoItemContainer>
        {cards.map((card, index) => (
          <div onClick={() => openCard(index)} key={index}>
            <Card card={card} />
          </div>
        ))}
      </CardInfoItemContainer>
    </Box>
  );
};
