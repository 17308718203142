import React from "react";

interface IconCashProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconCash = (props: IconCashProps) => {
  const { color = "#739B67", size = 20 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={color}
    >
      <path
        d="M3.73027 4.06081H16.2303C16.3763 4.06074 16.5222 4.07 16.667 4.08854C16.6179 3.74392 16.4995 3.41281 16.319 3.11518C16.1385 2.81754 15.8996 2.55955 15.6166 2.35675C15.3337 2.15395 15.0127 2.01054 14.6728 1.93517C14.333 1.85981 13.9814 1.85404 13.6393 1.91823L3.35918 3.67331H3.34746C2.70218 3.79671 2.12834 4.16179 1.74316 4.69401C2.32347 4.28125 3.01814 4.05989 3.73027 4.06081Z"
        fill={color}
      />
      <path
        d="M16.2305 5H3.73047C3.06765 5.00072 2.43218 5.26435 1.9635 5.73303C1.49482 6.20172 1.23119 6.83718 1.23047 7.5V15C1.23119 15.6628 1.49482 16.2983 1.9635 16.767C2.43218 17.2356 3.06765 17.4993 3.73047 17.5H16.2305C16.8933 17.4993 17.5287 17.2356 17.9974 16.767C18.4661 16.2983 18.7297 15.6628 18.7305 15V7.5C18.7297 6.83718 18.4661 6.20172 17.9974 5.73303C17.5287 5.26435 16.8933 5.00072 16.2305 5ZM14.375 12.5C14.1278 12.5 13.8861 12.4267 13.6805 12.2893C13.475 12.152 13.3148 11.9568 13.2201 11.7284C13.1255 11.4999 13.1008 11.2486 13.149 11.0061C13.1972 10.7637 13.3163 10.5409 13.4911 10.3661C13.6659 10.1913 13.8887 10.0722 14.1311 10.024C14.3736 9.97579 14.6249 10.0005 14.8534 10.0951C15.0818 10.1898 15.277 10.35 15.4143 10.5555C15.5517 10.7611 15.625 11.0028 15.625 11.25C15.625 11.5815 15.4933 11.8995 15.2589 12.1339C15.0245 12.3683 14.7065 12.5 14.375 12.5Z"
        fill={color}
      />
      <path
        d="M1.25 10.1348V6.24805C1.25 5.40156 1.71875 3.98242 3.3457 3.675C4.72656 3.41602 6.09375 3.41602 6.09375 3.41602C6.09375 3.41602 6.99219 4.04102 6.25 4.04102C5.50781 4.04102 5.52734 4.99805 6.25 4.99805C6.97266 4.99805 6.25 5.91602 6.25 5.91602L3.33984 9.2168L1.25 10.1348Z"
        fill={color}
      />
    </svg>
  );
};
