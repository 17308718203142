import Authorization from "organisms/Authorization";
import { useEffect, useState, useCallback } from "react";
import { authWebApi } from "api";
import { PinEnter } from "organisms/PinCode";
import { authActions } from "store/slices/auth";
import { authSelector, useDispatch, useSelector } from "store";
import { useIsPWA } from "hooks/useIsPWA";

export const SignIn = () => {
  const dispatch = useDispatch();
  const isPWA = useIsPWA();
  const [isPinChecked, setPinChecked] = useState(false);
  const { isPinAllowed, isPinForgotten, isCodeForgotten } =
    useSelector(authSelector);
  const checkPin = useCallback(async () => {
    if (!isPinForgotten) {
      const {
        data: { pinAllowed },
      } = await authWebApi.checkPinAllowed({
        deviceId:
          (isPWA
            ? localStorage.getItem("PWADeviceId")
            : localStorage.getItem("deviceId")) || "",
      });
      dispatch(authActions.setIsPinAllowed(pinAllowed || false));
      setPinChecked(true);
    } else {
      setPinChecked(true);
    }
  }, [dispatch, isPinForgotten, isPWA]);

  useEffect(() => {
    checkPin();
  }, [checkPin]);

  useEffect(() => {
    dispatch(authActions.setIsPinAllowed(isPinAllowed));
  }, [dispatch, isPinAllowed]);
  const { isAuthenticated, isConfirmed } = useSelector(authSelector);
  const isAuth = sessionStorage.getItem("isAuthenticated") === "true";
  const login = localStorage.getItem("login") || "";

  const isOk =
    !isPinForgotten &&
    !isAuthenticated &&
    !isConfirmed &&
    !isAuth &&
    isPinAllowed &&
    !!login.length;

  return isPinChecked ? (
    isOk && !isCodeForgotten ? (
      <PinEnter />
    ) : (
      <Authorization />
    )
  ) : (
    <Authorization />
  );
};
