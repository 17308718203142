import { Tab, TabText, TabWrapper } from "./components";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import { useDispatch, useSelector } from "store";
import { useIsMobile } from "hooks/useIsMobile";

const TABS = Object.values(HISTORY_TABS);
const TABS_MOBILE = [TABS[0], TABS[1], TABS[2]];

interface HistoryTabsProps {
  reset: () => void;
}

export const HistoryTabs = ({ reset }: HistoryTabsProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const { activeTab } = useSelector((state) => state.history);
  const tabs = isMobile ? TABS_MOBILE : TABS;

  return (
    <TabWrapper>
      {tabs.map((tab) => (
        <Tab
          key={tab}
          active={activeTab === tab}
          onClick={() => {
            if (activeTab === tab) return;
            reset && reset();
            dispatch(setActiveTab(tab));
          }}
          width={isMobile ? "50%" : "fit-content"}
          isMobile={isMobile}
        >
          <TabText active={activeTab === tab}>{tab}</TabText>
        </Tab>
      ))}
    </TabWrapper>
  );
};
