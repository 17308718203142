import React from "react";
import { authSelector, useSelector } from "store";
import { ProfileModalType } from "./models";
import { ProfileOption } from "./ProfileOption";
import { IconPinCode } from "atoms/IconPinCode/IconPinCode";
import { IconLock } from "atoms/IconLock/IconLock";
import { IconProfile } from "atoms/IconProfile/IconProfile";

interface Option {
  id: ProfileModalType;
  title?: string;
  icon?: JSX.Element;
}

const options: Option[] = [
  {
    id: ProfileModalType.PINCODE,
    title: "Создать код для входа",
    icon: <IconLock width={20} height={20} />,
  },
  {
    id: ProfileModalType.PASSWORD,
    title: "Изменить пароль",
    icon: <IconPinCode width={20} height={20} />,
  },
  {
    id: ProfileModalType.LOGIN,
    title: "Изменить логин",
    icon: <IconProfile width={20} height={20} />,
  },
];

interface ProfileTogglesRenderProps {
  handleOptionClick: (id: ProfileModalType) => void;
}

export const ProfileTogglesRender: React.FC<ProfileTogglesRenderProps> = ({
  handleOptionClick,
}) => {
  const { elseDevice, isPinAllowed } = useSelector(authSelector);

  return (
    <>
      {options.map((option) => (
        <ProfileOption
          key={option.id}
          title={
            option.id === ProfileModalType.PINCODE &&
            isPinAllowed &&
            !elseDevice
              ? "Изменить код для входа"
              : option.title || ""
          }
          id={option.id}
          onClick={() => handleOptionClick(option.id)}
          icon={option.icon}
        />
      ))}
    </>
  );
};
