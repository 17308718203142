import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AccountsList from "./components/AccountsList";
import CardsList from "./components/CardsList";
import { setStatement } from "store/slices/cardsSlice";
import { cardsSelector, useDispatch, useSelector } from "store";
import { SkeletonContainer } from "components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IconAddCard } from "atoms/IconAddCard";
import { setIsOpenVirtualCard } from "store/slices/virtualCardSlice";

const AccountsAndCardsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: "100%",
  position: "relative",

  "@media (max-width: 980px)": {
    marginBottom: "28px",
  },
});

const VirtualCardBlock = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: "4px",
  marginTop: "16px",
});

const VirtualCardText = styled(Typography)({
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "20px",
  color: "var(--brand-color-primary)",
  margin: 0,
});

export const AccountsAndCards: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(cardsSelector);
  const { isAvailable } = useSelector((state) => state.virtualCard);

  const isMatch = useMediaQuery("(max-width:980px)");

  const handleAddVirtualCard = () => {
    dispatch(setIsOpenVirtualCard(true));
  };

  useEffect(() => {
    dispatch(
      setStatement({
        withCheck: true,
        data: {
          accId: "%",
          forceRefresh: false,
        },
      })
    );
  }, [dispatch]);

  return (
    <Box>
      {isLoading ? (
        <SkeletonContainer
          height={!isMatch ? "272px" : "100px"}
          isLoading={isLoading}
          width="100%"
          marginBottom={isMatch ? 20 : 0}
        />
      ) : (
        <AccountsAndCardsContainer>
          <AccountsList />
          <CardsList />
          {isAvailable && (
            <VirtualCardBlock onClick={handleAddVirtualCard}>
              <IconAddCard />
              <VirtualCardText>Виртуальная карта</VirtualCardText>
            </VirtualCardBlock>
          )}
        </AccountsAndCardsContainer>
      )}
    </Box>
  );
};
