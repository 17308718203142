import styled from "@emotion/styled/macro";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { IconCopy } from "atoms/IconCopy/IconCopy";
import { IconVisibility } from "atoms/IconVisibility/IconVisibility";
import { IconVisibilityOff } from "atoms/IconVisibilityOff/IconVisibilityOff";
import copy from "copy-to-clipboard";
import React, { useEffect, useRef, useState } from "react";
import { CardCvvTokenDto, CardPanDto } from "../../api/account";
import { useDispatch } from "../../store";
import {
  getCardPanInfo,
  getTokenCardCvvInfo,
} from "../../store/slices/cardsSlice";
import SkeletonContainer from "../SkeletonContainer/SkeletonContainer";
import { CvvModal } from "./CvvModal";
import { IconChecked } from "atoms/IconChecked/IconChecked";

const IconButton = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

enum CardMessages {
  CARD_NUMBER = "Номер карты скопирован",
  EXPIRATION_DATE = "Срок действия карты скопирован",
}

const BackSideCard: React.FC = (card?: any) => {
  const dispatchApp = useDispatch();
  const [copyInfo, setCopyInfo] = useState<CardMessages | null>(null);
  const [copyPart, setCopyPart] = useState<CardMessages | null>(null);
  const [requisitesCard, setRequisitesCard] = useState<CardPanDto>({});
  const [verifyCvvToken, setVerifyCvvToken] = useState<string>("");
  const [show, setShow] = useState(false);
  const [cvv, setCvv] = useState<string | undefined>("");
  const [cvvModalVisible, setCvvModalVisible] = useState<boolean>(false);
  const copyCardPan = useRef<HTMLElement>(null);
  const copyLeftTime = useRef<HTMLElement>(null);

  const { card: currentCard } = card;

  const loadCardPan = () => {
    dispatchApp(getCardPanInfo({ cardId: card?.card?.cardId }))
      .unwrap()
      .then((res: CardPanDto) => {
        setRequisitesCard(res);
      })
      .catch((err) => {
        return err;
      });
  };

  const clearCVV = () => {
    setCvv("");
  };

  const getCVV = () => {
    dispatchApp(getTokenCardCvvInfo({ cardUID: card?.card.cardId }))
      .unwrap()
      .then((res: CardCvvTokenDto) => {
        if (res) {
          setShow(false);
          setRequisitesCard({});
          setCvvModalVisible(true);
          setVerifyCvvToken(res.verifyToken || "");
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const beautyCardPan = (cardPan: string | undefined, show: boolean) => {
    let string = "";
    if (requisitesCard.cardPan && show) {
      for (let i = 0; i < requisitesCard.cardPan?.length; i++) {
        if (i % 4 === 0) {
          string += " ";
        }
        string += requisitesCard.cardPan[i];
      }
    } else {
      string = "••••  ••••  ••••  " + cardPan?.slice(-4);
    }
    return string;
  };

  const beautyLifeTime = (lifeTime: string | undefined, show: boolean) => {
    if (lifeTime && show) {
      let arrTime = lifeTime?.split("-");
      //0 index - год, 1 index - месяц
      return String(arrTime[1] + "/" + arrTime[0][2] + arrTime[0][3]);
    } else {
      return "••/••";
    }
  };

  const copyText = async (copyId: string) => {
    let node: string | undefined;
    if (copyId === "copyCardPan") {
      node = copyCardPan?.current?.innerText;
    }
    if (copyId === "copyLeftTime") {
      node = copyLeftTime?.current?.innerText;
    }

    if (node) {
      copy(node);
      switch (copyId) {
        case "copyCardPan":
          setCopyInfo(CardMessages.CARD_NUMBER);
          setCopyPart(CardMessages.CARD_NUMBER);
          setTimeout(() => {
            setCopyInfo(null);
          }, 3000);
          break;
        case "copyLeftTime":
          setCopyInfo(CardMessages.EXPIRATION_DATE);
          setCopyPart(CardMessages.EXPIRATION_DATE);
          setTimeout(() => {
            setCopyInfo(null);
          }, 3000);
          break;
      }
    }
  };

  useEffect(() => {
    if (show === true) {
      loadCardPan();
      clearCVV();
    } else {
      setShow(false);
      setRequisitesCard({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setShow(false);
    setRequisitesCard({});
    setCopyPart(null);
    clearCVV();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setShow(false);
        setCvv("");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const matches = useMediaQuery("(max-width: 1024px)");
  const isSmall = useMediaQuery("(max-width: 478px)");

  return (
    <>
      <>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          order={{ xs: 5, md: 5 }}
          mt={28}
        >
          <Typography
            sx={{
              color: "#454A3F",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Реквизиты карты
          </Typography>
          <Box onClick={() => setShow(!show)}>
            <Typography
              variant="text_3"
              color="blue.b400"
              fontWeight="500"
              sx={{ cursor: "pointer", fontSize: "16px" }}
            >
              {show ? "Скрыть" : "Показать"}
            </Typography>
          </Box>
        </Box>
      </>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        mt={12}
        mb={24}
        order={{ xs: 6, md: 6 }}
      >
        <SkeletonContainer width="100%">
          {copyInfo && (
            <Box
              sx={{
                width: isSmall
                  ? "calc(100% - 32px)"
                  : matches
                  ? "444px"
                  : "412px",
              }}
              alignItems="center"
              display="flex"
              bgcolor="#656960"
              padding="12px"
              borderRadius="8px"
              boxShadow="3px 7px 9.6px 0px rgba(139, 143, 135, 0.34)"
              position="fixed"
              boxSizing="border-box"
              bottom="40px"
              right={matches ? "16px" : "32px"}
              zIndex={100}
            >
              <Box display="flex" mr={10}>
                <IconChecked />
              </Box>
              <Typography
                sx={{
                  fontSize: "17px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#fff",
                }}
              >
                {copyInfo}
              </Typography>
            </Box>
          )}

          <Box
            width="100%"
            bgcolor="white"
            mb={{ xs: 12 }}
            borderRadius="16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="9px 12px"
            boxSizing="border-box"
            sx={{ backgroundColor: "#F5F5F5" }}
          >
            <Box flexDirection="column" display="flex" gap={2}>
              <Typography
                sx={{
                  color: "#7E7F7A",
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "14px",
                }}
              >
                Номер карты
              </Typography>
              <Typography
                sx={{
                  color: "#454A3F",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
                ref={copyCardPan}
              >
                {beautyCardPan(currentCard.cardPan, show)}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                if (!show) return;
                copyText("copyCardPan");
              }}
              style={{
                cursor: !show ? "auto" : "pointer",
              }}
            >
              <IconCopy
                color={
                  copyPart === "Номер карты скопирован" ? "#B5CDA3" : "#739B67"
                }
              />
            </IconButton>
          </Box>
          <Box display="flex" gap={12}>
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="16px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="9px 12px"
              boxSizing="border-box"
              sx={{ backgroundColor: "#F5F5F5" }}
            >
              <Box flexDirection="column" display="flex" gap={2}>
                <Typography
                  sx={{
                    color: "#7E7F7A",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "14px",
                  }}
                >
                  Срок действия
                </Typography>
                <Typography
                  sx={{
                    color: "#454A3F",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                  ref={copyLeftTime}
                >
                  {beautyLifeTime(requisitesCard?.lifetime, show)}
                </Typography>
              </Box>
              <IconButton
                onClick={() => {
                  if (!show) return;
                  copyText("copyLeftTime");
                }}
                style={{
                  cursor: !show ? "auto" : "pointer",
                }}
              >
                <IconCopy
                  color={
                    copyPart === "Срок действия карты скопирован"
                      ? "#B5CDA3"
                      : "#739B67"
                  }
                />
              </IconButton>
            </Box>
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="16px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="9px 12px"
              boxSizing="border-box"
              sx={{ backgroundColor: "#F5F5F5" }}
            >
              <Box flexDirection="column" display="flex" gap={2}>
                <Typography
                  sx={{
                    color: "#7E7F7A",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "14px",
                  }}
                >
                  CVV2
                </Typography>
                <Typography
                  sx={{
                    color: "#454A3F",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  {cvv ? cvv : "•••"}
                </Typography>
              </Box>
              {cvv && (
                <Box
                  onClick={() => clearCVV()}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconVisibility />
                </Box>
              )}
              {!cvv && (
                <Box
                  onClick={getCVV}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconVisibilityOff />
                </Box>
              )}
            </Box>
          </Box>
        </SkeletonContainer>
      </Box>
      {cvvModalVisible && (
        <CvvModal
          isOpen={cvvModalVisible}
          verifyCvvToken={verifyCvvToken}
          setCvv={setCvv}
          setCvvModalVisible={setCvvModalVisible}
          setRequisitesCard={setRequisitesCard}
        />
      )}
    </>
  );
};

export default BackSideCard;
