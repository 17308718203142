import { useCallback, useEffect, useRef, useState } from "react";

export const useCounter = () => {
  const [count, setCount] = useState(59);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clear = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const interval = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setCount((prevCount) => {
        if (prevCount <= 1) {
          clear();
          return 0;
        }
        return prevCount - 1;
      });
      interval();
    }, 1000);
  }, []);

  const restartCounter = () => {
    clear();
    setCount(59);
    interval();
  };

  useEffect(() => {
    interval();
    return () => {
      clear();
    };
  }, [interval]);

  useEffect(() => {
    if (count === 0) clear();
  }, [count]);

  return {
    count,
    restartCounter,
  };
};
