import { ChatMessageDto } from "api/notification";
import { Typography, Box } from "@mui/material";
import theme from "theme";
import { formattedDate } from "store/slices/chat/utils";
import { MessageItem } from "../MessageItem";
import { useState } from "react";
import { ChatMessageMenu } from "../ChatMessage/ChatMessageMenu";

export enum ChatBlockDirection {
  IN = "IN",
  OUT = "OUT",
  SERVICE = "SERVICE",
}

export interface ChatBlockProps {
  date: string;
  messages: ChatMessageDto[];
  isWindow: boolean;
  isReply: boolean;
  onMsgSelect: (msg: ChatMessageDto) => void;
  onReply: (msg: boolean) => void;
  selectedMsg: ChatMessageDto | null;
}

const sortByDate = (messages: ChatMessageDto[]) => {
  return messages.sort((messageA, messageB) => {
    const dateA = new Date(messageA.createdAt || "").getTime();
    const dateB = new Date(messageB.createdAt || "").getTime();

    return dateA - dateB;
  });
};

export const ChatBlock = ({
  messages,
  date,
  isWindow,
  onMsgSelect,
  selectedMsg,
  onReply,
  isReply,
}: ChatBlockProps) => {
  return (
    <Box marginTop="auto">
      <Typography
        typography="text_3"
        color={theme.primary.main.gray["300"]}
        textAlign="center"
      >
        {formattedDate(date)}
      </Typography>
      {sortByDate(messages).map((message) => (
        <MessageItem
          message={message}
          key={message.id}
          isWindow={isWindow}
          onMsgSelect={() => onMsgSelect(message)}
          selectedMsg={selectedMsg}
          onReply={onReply}
          isReply={isReply}
        />
      ))}
    </Box>
  );
};
