import { useEffect, useMemo, useState } from "react";
import { TransfersSteps } from "./TransfersSteps";
import { useIsMobile } from "hooks/useIsMobile";
import {
  resetTransferErrorMessage,
  createTransferToCard,
  TRANSFER_ERROR_CODE,
  resetStateTransfer,
  resetConfirmedTransfer,
  setIsOpenModal,
  setTransferClientBankIcon,
} from "store/slices/transferSlice";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import {
  setChosenCardIndex,
  setFromTransferCard,
  setStatement,
} from "store/slices/cardsSlice";
import { useDispatch, useSelector } from "store";
import {
  MAX_AMOUNT,
  MAX_TRANSFER_PAN_MIN_LENGTH,
  MAX_TRANSFER_PAN_MAX_LENGTH,
} from "constants/limits";
import { ITransferState, TabVariants } from "store/slices/system/slice";
import { CardStatus, TransferInfoResponseTransferParam } from "api/account";
import { systemActions } from "store/slices/system";
import TransferForm from "./TransferForm";

export interface PayloadModal {
  cardId: string | undefined;
  toCardPan: string;
  sum: number;
  reason: string;
}

const Transfers = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();

  const {
    cards: {
      mainCard,
      allCards,
      account: { currency },
      chosenCardIndex,
    },
    transfer: {
      isTransferChecked,
      isTransferConfirmed,
      createdTransfer,
      errorMessage,
      isOpenModal,
      isLoading,
    },
    system: { activeTabState },
  } = useSelector((state) => state);

  const activeCard =
    chosenCardIndex !== undefined
      ? allCards[chosenCardIndex]
      : allCards.find((item) => item.status === CardStatus.Active);
  const countActiveCards = useMemo(
    () =>
      allCards.reduce<number>((prev, value) => {
        return value.status === CardStatus.Active ? prev + 1 : prev;
      }, 0),
    [allCards]
  );
  const hasActiveCard = countActiveCards > 0;

  const [payloadModal, setPayloadModal] = useState({} as PayloadModal);
  const [errorAmount, setErrorAmount] = useState("");

  const [transferValues, setTransferValues] =
    useState<ITransferState>(activeTabState);

  const { pan, amount, reason } = transferValues;

  const ERROR_MESSAGE_AMOUNT = "Ошибка: Превышен лимит";

  const toggleModal = (
    repeatValue: string = "",
    {
      toCardPan: pan,
      sum: amount,
      reason,
    }: TransferInfoResponseTransferParam = {}
  ) => {
    if (isOpenModal) {
      setTimeout(() => {
        dispatch(resetTransferErrorMessage());
      }, 200);
    }
    dispatch(setIsOpenModal(!isOpenModal));
    if (
      isOpenModal &&
      isTransferChecked &&
      isTransferConfirmed &&
      !createdTransfer
    ) {
      const payload = repeatValue
        ? { pan, amount, reason }
        : { pan: "", amount: undefined, reason: undefined };
      dispatch(
        systemActions.setActiveTab({
          tab: TabVariants.transfersHub,
          state: payload,
        })
      );
      dispatch(
        setStatement({
          data: {
            accId: "%",
            forceRefresh: false,
          },
        })
      );
      dispatch(resetConfirmedTransfer());

      setTransferValues({
        pan: "",
        amount: NaN,
        reason: "",
      });
    }
  };

  const continueHandler = () => {
    dispatch(resetStateTransfer());
    const data = {
      cardId: activeCard?.cardId || "",
      toCardPan: pan || "",
      sum: amount || 0,
      reason: reason || "",
    };
    dispatch(createTransferToCard(data))
      .unwrap()
      .then((res) => {
        if (res?.limitRestriction) {
          throw new Error("Лимит по карте превышен");
        } else {
          dispatch(setTransferClientBankIcon(res.toClientBankIcon as string));
          dispatch(setFromTransferCard(mainCard));
          setPayloadModal(data);
          toggleModal();
        }
      })
      .catch((res) => {
        if (res?.response?.status === 400) return;
        setErrorAmount(res.message);
      });
  };

  useEffect(() => {
    return () => {
      dispatch(resetTransferErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeTabState.pan) setTransferValues(activeTabState);
  }, [activeTabState]);

  const errorMessageAmount =
    amount && amount >= MAX_AMOUNT ? ERROR_MESSAGE_AMOUNT : "";
  const isPanValid =
    !!pan &&
    pan.length >= MAX_TRANSFER_PAN_MIN_LENGTH &&
    pan.length <= MAX_TRANSFER_PAN_MAX_LENGTH;
  const isAmountValid = !!amount && amount > 0 && !errorMessageAmount;
  const isTransferValid =
    isPanValid && isAmountValid && activeCard?.status === "ACTIVE";
  const isMessageError = errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE;
  const isCardError = errorMessage.code === TRANSFER_ERROR_CODE.CARD;
  const isAmountExceedError =
    errorMessage.code === TRANSFER_ERROR_CODE.AMOUNT_EXCEED_BALANCE;
  const isErrorInFields = isMessageError || isCardError || isAmountExceedError;

  const currencySign = currency?.sign || "";

  const goToHandler = (tab: TabVariants) => {
    if (tab === TabVariants.history) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setChosenCardIndex(0));
    };
  }, [dispatch]);

  return (
    <>
      {!isOpenModal ? (
        <>
          <TransferForm
            isMobile={isMobile}
            activeCard={activeCard}
            hasActiveCard={hasActiveCard}
            currencySign={currencySign}
            amount={amount}
            errorMessageAmount={errorMessageAmount}
            isAmountExceedError={isAmountExceedError}
            errorMessage={errorMessage}
            errorAmount={errorAmount}
            pan={pan}
            reason={reason}
            isCardError={isCardError}
            isMessageError={isMessageError}
            dispatch={dispatch}
            setErrorAmount={setErrorAmount}
            setTransferValues={setTransferValues}
            transferValues={transferValues}
            goToHandler={goToHandler}
            continueHandler={continueHandler}
            isTransferValid={isTransferValid}
            isErrorInFields={isErrorInFields}
            isLoading={isLoading}
          />
        </>
      ) : (
        <TransfersSteps onClose={toggleModal} payloadModal={payloadModal} />
      )}
    </>
  );
};

export default Transfers;
