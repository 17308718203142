import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { profileActions } from "../../store/slices/profile";
import { useDispatch } from "store";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    "& > div > .arrow > svg > path": {
      transition: ".2s",
    },
    "&:hover": {
      transition: ".2s",
      "& > svg": {
        transition: ".2s",
        boxShadow: "0px 0px 38px -10px rgba(34, 60, 80, 0.76)",
        borderRadius: "15px",
        backgroundColor: theme.palette.grey["100"],
      },
      "& > div > .arrow > svg > path": {
        transition: ".2s",
        fill: theme.palette.gray.b900,
      },
    },
  },
}));

export const AvatarAndName = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = () => {
    dispatch(profileActions.setIsModalOpened(true));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mr={8}
        className={classes.icon}
        onClick={handleClick}
        {...props}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="text_5" color="gray.b900" fontWeight={500}>
            Профиль
          </Typography>
        </Box>
      </Box>
    </>
  );
};
