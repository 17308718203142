import { useCallback, useEffect } from "react";
import { throttle } from "utils/throttle";
import { INACTIVITY_TIMEOUT } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { authActions } from "store/slices/auth";
import { useDispatch } from "store";

// События, которые считаются активностью пользователя
const events = [
  "mousemove",
  "mousedown",
  "keydown",
  "click",
  "touchstart",
  "wheel",
  "scroll",
];

// Задержка для throttling
const THROTTLE_DELAY = 2000;
const EXPIRE_TIME_KEY = "expireTime";

const ActivityMonitor = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  // Функция для обновления времени истечения активности
  const updateExpiryTime = () => {
    const expireTime = Date.now() + INACTIVITY_TIMEOUT;
    localStorage.setItem(EXPIRE_TIME_KEY, expireTime.toString());
    return expireTime;
  };

  const checkInactivity = useCallback(() => {
    const expireTimeString = localStorage.getItem(EXPIRE_TIME_KEY);
    let expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

    if (!expireTime) expireTime = updateExpiryTime();

    if (expireTime < Date.now()) {
      dispatch(authActions.setLogout());

      sessionStorage.removeItem("isAuthenticated");
      sessionStorage.removeItem("accessToken");
      localStorage.removeItem(EXPIRE_TIME_KEY);
      nav("/pin-enter", { replace: true });
    }
  }, [dispatch, nav]);

  useEffect(() => {
    const throttledUpdateExpiryTime = throttle(
      updateExpiryTime,
      THROTTLE_DELAY
    );

    events.forEach((event) => {
      window.addEventListener(event, throttledUpdateExpiryTime);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, throttledUpdateExpiryTime);
      });
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactivity();
    }, 10000);

    return () => clearInterval(interval);
  }, [checkInactivity]);

  return null;
};

export default ActivityMonitor;
// втавлять в компонент, который появляется лишь когда пользователь активный. в крайнем случае создать обертку для всех активных пайдж компонентов
