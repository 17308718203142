import { EmptyStyled } from "../../History";
import { useSelector } from "store";
import { HISTORY_TABS } from "store/slices/historySlice";
import IconNoNotifications from "atoms/IconNoNotifications/IconNoNotifications";

export const EmptyScreenDefault = () => {
  const {
    history: { activeTab },
  } = useSelector((state) => state);

  const dateNow = new Date();
  let currentMonth = dateNow.toLocaleString("ru", { month: "long" });
  if (currentMonth === "март" || currentMonth === "август") {
    currentMonth = currentMonth + "е";
  } else {
    currentMonth = currentMonth.substring(0, currentMonth.length - 1) + "е";
  }

  let description = "операций";
  if (activeTab === HISTORY_TABS.EXPENSES) {
    description = "расходов";
  }
  if (activeTab === HISTORY_TABS.INCOMES) {
    description = "поступлений";
  }

  return (
    <EmptyStyled>
      <IconNoNotifications />
      <h2>
        В {currentMonth} {description} по карте ещё не было
      </h2>
    </EmptyStyled>
  );
};
