import { Box } from "@mui/material";
import { CardLimitConfirmResponse } from "api/account";
import { IconArrows } from "atoms/IconArrows/IconArrows";
import { IconCash } from "atoms/IconCash/IconCash";
import { IconGlobe } from "atoms/IconGlobe/IconGlobe";
import { IconWallet } from "atoms/IconWallet/IconWallet";
import { SkeletonContainer } from "components/SkeletonContainer";
import { LimitItem } from "pages/home/Cards/Limits/LimitItem";
import { LimitModal } from "pages/home/Cards/Limits/LimitModal";
import { Screens } from "pages/home/Cards/Limits/models";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "store";
import { hideErrorMessage } from "store/slices/alerts";
import { getLimits } from "store/slices/limitsSlice";

function LimitCard() {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.limits);
  const [isOpen, setIsOpen] = useState(false);
  const [screen, setScreen] = useState<Screens>("initial");
  const [selected, setSelected] = useState<number | null>(null);

  const [limit, setLimitItem] = useState<CardLimitConfirmResponse>({});

  const handleClose = () => {
    dispatch(hideErrorMessage());
    setIsOpen(!isOpen);
    setScreen("initial");
  };

  const handleClick = (id: number) => {
    setLimitItem(
      limits.filter(
        (limit: CardLimitConfirmResponse) => limit?.limitId === id
      )[0]
    );
    setIsOpen(true);
    setSelected(id);
  };
  const {
    limits: { limits, isLoading },
    cards: { chosenCardIndex, allCards },
  } = useSelector((state) => state);

  const activeCard = allCards[chosenCardIndex || 0];

  useEffect(() => {
    dispatch(getLimits({ cardId: activeCard.cardId as string }));
  }, [activeCard.cardId, dispatch]);

  useEffect(() => {
    if (error.code === "LIMIT_ERROR") {
      setScreen("unsuccess");
      setIsOpen(true);
    }
  }, [error.code]);

  const dataIcon: any = useMemo(
    () => [
      () => <IconArrows />,
      () => <IconWallet />,
      () => <IconGlobe />,
      () => <IconCash />,
    ],
    []
  );

  return (
    <>
      <SkeletonContainer height={400} width="100%" isLoading={isLoading}>
        <Box display="flex" gap={12} flexDirection="column">
          {[...limits]
            .sort((a, b) =>
              a.limitId === 3053 ? -1 : b.limitId === 3053 ? 1 : 0
            )
            .map(
              (
                {
                  limitType,
                  limitName,
                  limitId,
                  limitCurrentAmount,
                  limitSumAmount,
                  readOnly,
                }: any,
                idx
              ) => (
                <LimitItem
                  selected={limitId === selected}
                  onClick={handleClick}
                  key={limitId}
                  id={limitId}
                  subtitle={limitType}
                  title={limitName}
                  limitCurrentAmount={limitCurrentAmount}
                  limitSumAmount={limitSumAmount}
                  readOnly={readOnly}
                  svg={dataIcon[idx]}
                  isFirst={limitId === 3053}
                />
              )
            )}
        </Box>
      </SkeletonContainer>
      <LimitModal
        isOpen={isOpen}
        onClose={handleClose}
        screen={screen}
        onChangeScreens={setScreen}
        limitItem={limit}
      />
    </>
  );
}

export default LimitCard;
