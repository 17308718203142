import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ChatAttachmentDto } from "api/notification";
import { isImage } from "utils/isImage";
import { IconDocument } from "atoms/IconDocument/IconDocument";

interface ReplyMessageProps {
  user?: string;
  text?: string;
  attachments?: ChatAttachmentDto[];
  isClientMessage?: boolean;
  isReply?: boolean;
}

export const ReplyMessage: React.FC<ReplyMessageProps> = ({
  user,
  text,
  attachments,
  isClientMessage = false,
  isReply,
}) => {
  const [files, setFiles] = useState<ChatAttachmentDto[]>([]);

  useEffect(() => {
    setFiles(attachments || []);
  }, [attachments]);

  return (
    <Box
      sx={{
        borderRadius: 6,
        padding: "4px 0px",
        backgroundColor: "rgba(120, 173, 105, 0.15)",
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderLeft: "#739B67 2px solid",
        }}
      >
        <Box sx={{ paddingLeft: 8 }}>
          {user && (
            <Typography
              sx={{
                color: "#739B67",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "14px",
              }}
            >
              {user}
            </Typography>
          )}
          {!user && !isClientMessage && !isReply && (
            <Typography
              sx={{
                color: "#739B67",
                fontSize: "12px",
                fontWeight: 300,
                lineHeight: "14px",
              }}
            >
              Оператор
            </Typography>
          )}

          <Typography
            sx={{
              color: "#454A3F",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
            }}
          >
            {text}
          </Typography>

          {/* Files */}

          {files.map((file) => (
            <FilePreview key={file.id} file={file} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

interface FilePreviewProps {
  file: ChatAttachmentDto;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file }) => {
  const isFileImage = isImage(file.mimeType);

  return (
    <Box
      sx={{ cursor: "pointer" }}
      key={file.id}
      display="flex"
      alignItems="center"
      gap={1.5}
    >
      {isFileImage ? (
        <Box sx={{ height: 117, overflow: "hidden", borderRadius: 2 }}>
          <img
            src={`${process.env.REACT_APP_BASE_PATH}/notification/v2/chat/attachment/${file.id}`}
            loading="lazy"
            style={{ height: "100%" }}
            alt={file.fileName}
            onError={(e) => {
              (e.target as HTMLImageElement).src = "/path/to/placeholder.jpg";
            }}
          />
        </Box>
      ) : (
        <IconDocument />
      )}
      {!isFileImage && (
        <Typography
          typography="text_5"
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: 150,
          }}
          title={file.fileName}
        >
          {file.fileName}
        </Typography>
      )}
    </Box>
  );
};
