import PinInput from "react-pin-input";

interface CodeInputProps {
  setCode: (value: string) => void;
  error: boolean;
  clearError: () => void;
  setRef?: (ref: PinInput | null) => void;
  length: number;
  secret?: boolean;
  secretDelay?: number;
  color?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const CodeInput = ({
  error,
  setCode,
  clearError,
  length,
  setRef,
  secret,
  secretDelay,
  color,
  disabled,
  style,
}: CodeInputProps) => (
  <PinInput
    secret={secret}
    secretDelay={secretDelay}
    ref={(n) => {
      if (setRef) setRef(n);
    }}
    length={length}
    onChange={(value) => {
      setCode(value);
      if (error) clearError();
    }}
    type="numeric"
    inputMode="numeric"
    aria-hidden="true"
    style={{
      padding: "10px",
      display: "flex",
      gap: "3px",
      justifyContent: "center",
      ...style,
    }}
    inputStyle={{
      borderColor: "var(--main-color-border-icon)",
      border: "none",
      borderRadius: "5px",
      width: "32px",
      fontSize: "22px",
      fontWeight: 400,
      lineHeight: "27.5px",
      background: error ? "var(--error-color-notification)" : "#FAFAFA",
      color: error
        ? "var(--error-color-icon)"
        : color || "var(--main-color-text-title)",
    }}
    inputFocusStyle={{
      borderColor: error ? "none" : "var(--main-color-border-icon)",
    }}
    focus={true}
    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
    disabled={disabled}
  />
);
