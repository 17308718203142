import React from "react";

interface IconArrowsProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconArrows = (props: IconArrowsProps) => {
  const { fill = "#739B67", size = 20 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        x="1.66699"
        y="1.66699"
        width="16.6667"
        height="16.6667"
        rx="5"
        fill={fill}
      />
      <path
        d="M7.03736 10.7411H9.25958M9.25958 10.7411V12.9633M9.25958 10.7411L6.66699 13.3337M12.9633 9.25958H10.7411M10.7411 9.25958V7.03736M10.7411 9.25958L13.3337 6.66699"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
