import { FC, useState } from "react";
import { DatePicker } from "components";
import { SearchFiltersValues } from "pages/home/Cards/History/History";
import { parseDate } from "utils";
import { useSelector } from "store";
import { HISTORY_TABS } from "store/slices/historySlice";
import {
  ArrowsBlock,
  DateWrapper,
  HeaderMonth,
  HeaderRight,
  HeaderWrapper,
  Wrapper,
} from "../../../FinancialAnalysisWidget/components";
import { useOutsideClick } from "hooks/useOutsideClick";
import { getEndOfDay } from "utils/getEndOfDay";
import moment from "moment";
import { isFullMonthRange } from "utils/dateUtils";
import { MONTHS } from "constants/months";
import { IconCalendar } from "atoms/IconCalendar/IconCalendar";
import { IconChevronLeft } from "atoms/IconChevronLeft/IconChevronLeft";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { Button } from "components/Button";

export const ERROR_MESSAGE_DATEFROM_INTERVAL =
  "Начальная дата не может быть позже конечной";
export const ERROR_MESSAGE_DATEFROM_TODAY =
  "Начальная дата не может быть позже сегодняшней";
export const ERROR_MESSAGE_DATETO_INTERVAL =
  "Конечная дата не может быть раньше начальной";
export const ERROR_MESSAGE_DATETO_TODAY =
  "Конечная дата не может быть позже сегодняшней";

export type ValueDateType = {
  from: string;
  to: string;
};

interface OperationDateProps {
  value: ValueDateType;
  appliedValues: ValueDateType;
  changeDateArrowHandler: (value: ValueDateType) => void;
  isMobile: boolean;
  onChange: (values: Partial<SearchFiltersValues>) => void;
  setAppliedValues?: (value: ValueDateType) => void;
  onSearch?: () => void;
  filterValues?: SearchFiltersValues;
  isHideArrows?: boolean;
  position?: "left" | "right";
  fullWidth?: boolean;
  isDisabled?: boolean;
}

export const OperationDate: FC<OperationDateProps> = ({
  value,
  onChange,
  onSearch,
  appliedValues,
  setAppliedValues,
  isHideArrows,
  changeDateArrowHandler,
  isMobile,
  isDisabled,
  position = "left",
}) => {
  const dateNow = new Date();
  const monthNumber = dateNow.getMonth();
  const yearNumber = dateNow.getFullYear();

  const currentMonth = dateNow.toLocaleString("ru", { month: "long" });
  const currentYear = dateNow.toLocaleString("ru", { year: "numeric" });

  let fromText = parseDate(appliedValues?.from);
  const toText = parseDate(appliedValues?.to);

  const fromTextYear = fromText.split(".").at(-1);
  const toTextYear = toText.split(".").at(-1);

  if (fromTextYear === toTextYear) {
    const fromTextArr = fromText.split(".");
    fromTextArr.pop();
    fromText = fromTextArr.join(".");
  }

  const dateAnchor = appliedValues?.from
    ? appliedValues?.to
      ? `${fromText} - ${toText}`
      : `с ${fromText}`
    : appliedValues?.to && toText !== parseDate(new Date().toISOString())
    ? `по ${toText}`
    : "";

  const isFullMonth = isFullMonthRange(
    new Date(value.from),
    new Date(value.to)
  );

  const dateNumbers = toText.split(".").map((number) => Number(number));
  const monthDate = `${MONTHS[dateNumbers[1] - 1]} ${dateNumbers[2]}`;

  const isDisabledRightArrow =
    currentMonth.toLowerCase() === MONTHS[dateNumbers[1] - 1]?.toLowerCase() &&
    Number(currentYear) === Number(dateNumbers[2]);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isDropped, setDropped] = useState<boolean>(false);
  const [lastModified, setLastModified] = useState<"from" | "to" | null>(null);
  const {
    history: { activeTab },
  } = useSelector((state) => state);

  const changeDateLeft = () => {
    if (isDisabled) return;

    const from = moment(value.from).subtract(1, "month").toDate().toISOString();
    const date = moment(value.from).subtract(1, "day").toDate();

    date.setHours(23, 59, 59, 999);
    const to = date.toISOString();
    onChange({ from, to, page: 0 });

    changeDateArrowHandler({ from, to });
  };

  const changeDateRight = () => {
    if (isDisabledRightArrow || isDisabled) return;

    const from = moment(value.from).add(1, "month").toDate().toISOString();
    const date = moment(value.from).add(2, "month").subtract(1, "day").toDate();

    if (monthNumber === date.getMonth() && yearNumber === date.getFullYear()) {
      date.setDate(dateNow.getDate());
    }

    date.setHours(23, 59, 59, 0);
    const to = date.toISOString();
    onChange({ from, to, page: 0 });

    changeDateArrowHandler({ from, to });
  };

  const handleSearch = () => {
    if (activeTab === HISTORY_TABS.ALL_OPERATIONS && isMobile) {
      changeDateArrowHandler(value);
    }
    setDropped(false);
    setAppliedValues && setAppliedValues(value);
    onSearch && onSearch();
  };

  const handleDrop = () => {
    setDropped(!isDropped);
  };
  const handleBlur = () => {
    setDropped(false);
  };

  const rootElement = useOutsideClick(handleBlur, { useCapture: true });

  const errorMessageFrom =
    lastModified === "from" && value.from > value.to
      ? ERROR_MESSAGE_DATEFROM_INTERVAL
      : value.from > new Date().toISOString()
      ? ERROR_MESSAGE_DATEFROM_TODAY
      : "";

  const errorMessageTo =
    lastModified === "to" && value.to < value.from
      ? ERROR_MESSAGE_DATETO_INTERVAL
      : value.to > getEndOfDay(dateNow)
      ? ERROR_MESSAGE_DATETO_TODAY
      : "";

  const content = (
    <DateWrapper ref={rootElement} position={position}>
      <DatePicker
        label="Начало периода"
        placeholder="00.00.0000"
        value={value.from ? new Date(value.from) : null}
        error={!!errorMessageFrom}
        hint={errorMessageFrom}
        onChange={(from: Date | null) => {
          setIsSubmitDisabled(false);
          setLastModified("from");
          onChange({ from: from?.toISOString() });
        }}
      />
      <DatePicker
        label="Конец периода"
        value={value.to ? new Date(value.to) : null}
        error={!!errorMessageTo}
        hint={errorMessageTo}
        onChange={(to: Date | null) => {
          setIsSubmitDisabled(false);
          setLastModified("to");
          onChange({ to: getEndOfDay(to!) });
        }}
      />
      <Button
        variant="primary"
        onClick={handleSearch}
        disabled={!!errorMessageFrom || !!errorMessageTo || isSubmitDisabled}
        title="Применить фильтр"
      />
    </DateWrapper>
  );

  const header = (
    <HeaderRight onClick={handleDrop} style={{ cursor: "pointer" }}>
      <IconCalendar />
      <HeaderMonth style={{ color: "#739B67" }}>
        {isFullMonth ? monthDate : dateAnchor}
      </HeaderMonth>
    </HeaderRight>
  );

  const isShowArrows = (isFullMonth || position === "right") && !isHideArrows;

  return (
    <Wrapper position={position}>
      <HeaderWrapper
        justify={position === "right" ? "flex-end" : "space-between"}
      >
        {position === "left" && header}

        <ArrowsBlock isMobile={isMobile}>
          {isShowArrows && (
            <div
              style={{
                cursor: !isDisabled ? "pointer" : "auto",
              }}
              onClick={changeDateLeft}
            >
              <IconChevronLeft color={isDisabled ? "#8B8C88" : undefined} />
            </div>
          )}

          {position === "right" && header}

          {isShowArrows && (
            <div
              style={{
                cursor:
                  !isDisabledRightArrow && !isDisabled ? "pointer" : "auto",
              }}
              onClick={changeDateRight}
            >
              <IconChevronRight
                color={
                  isDisabledRightArrow || isDisabled ? "#8B8C88" : undefined
                }
              />
            </div>
          )}
        </ArrowsBlock>
      </HeaderWrapper>
      {isDropped && content}
    </Wrapper>
  );
};
