import ArrowLeft from "./assets/ArrowLeft";
import { Box, Dialog, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { IconClose } from "atoms/IconClose/IconClose";
import { useIsMobile } from "hooks/useIsMobile";
import { useResetStore } from "hooks/useResetStore";
import { useNavigate } from "react-router-dom";
import { profileSelector, useDispatch, useSelector } from "store";
import { hideErrorMessage } from "store/slices/alerts";
import { authActions } from "store/slices/auth";
import { useAuthCommonActions } from "hooks";

const DialogContainer = styled(Dialog)(({ theme, top, isMobile }) => ({
  backdropFilter: "blur(3.25px)",

  "& .MuiPaper-root": {
    margin: 0,
    padding: 0,
    paddingTop: 0,
    boxSizing: "border-box",
    maxHeight: "100%",
    maxWidth: "476px",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: top && isMobile ? top : 0,
    boxShadow: top && isMobile && "none",
    right: 0,
    borderRadius: 0,
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      boxSizing: "border-box",
      maxHeight: "100%",
      width: "100%",
      overflowX: "hidden",
      // padding: theme.spacing(16),
      paddingTop: 0,
    },
  },
}));

export const Modal = ({
  title,
  subTitle = "",
  isOpen,
  onClose,
  buttonVisible,
  callback,
  children,
  centerText = false,
  isProfile = false,
  headerTop = false,
  showTitle = true,
  isCenteredTitle = false,
  titleMarginTop = "",
  center = false,
  ...props
}) => {
  const { isMobile } = useIsMobile();
  const top = props.top;
  const dispatch = useDispatch();
  const { isLoginUpdated, updatePasswordStep } = useSelector(profileSelector);
  const isPasswordUpdated = updatePasswordStep === "SUCCESS_UPDATE";
  const navigate = useNavigate();
  const resetStore = useResetStore();
  const { handleLogout } = useAuthCommonActions();

  const handleClose = () => {
    if (isLoginUpdated || isPasswordUpdated) {
      dispatch(authActions.setLogout());
      const deviceIdItem = localStorage.getItem("deviceId");
      const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
      localStorage.clear();
      if (deviceIdItem !== null) localStorage.setItem("deviceId", deviceIdItem);
      if (PWAdeviceIdItem !== null)
        localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
      sessionStorage.clear();
      navigate("/sign-in", { replace: true });
      resetStore();
    } else {
      onClose();
      dispatch(hideErrorMessage());
    }
  };

  const onBack = () => {
    if (buttonVisible) {
      callback();
    } else {
      onClose();
    }
    dispatch(hideErrorMessage());
  };

  const paddingX = isMobile ? 16 : 32;
  const paddingY = isMobile ? 28 : 32;

  const Title = () => (
    <Box
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "350px",
        marginTop: titleMarginTop,
        ...(center && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }),
        ...(isProfile &&
          isMobile && {
            width: "calc(100% - 49px)",
            textAlign: headerTop ? "left" : "center",
            display: "flex",
            justifyContent: headerTop ? "flex-start" : "center",
            alignItems: "center",
            gap: "5px",
          }),
      }}
    >
      {typeof title === "string" ? (
        <Typography
          sx={{
            color: "#454A3F",
            fontSize: isMobile ? "18px" : "24px",
            fontWeight: 500,
            display: "inline",
            lineHeight: isMobile ? "24px" : "normal",
          }}
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      {subTitle && (
        <Typography
          noWrap
          sx={{
            color: "#7E7F7A",
            fontSize: isMobile ? "18px" : "24px",
            fontWeight: 500,
            display: "inline",
            lineHeight: "normal",
            ...(headerTop && { alignSelf: "flex-end" }),
          }}
        >
          • {subTitle}
        </Typography>
      )}
    </Box>
  );

  return (
    <DialogContainer
      hideBackdrop={top && isMobile}
      open={isOpen}
      onClose={handleClose}
      {...props}
      isMobile={isMobile}
    >
      {/* Header */}
      <Box
        display="flex"
        alignItems={headerTop ? "flex-start" : "center"}
        justifyContent={isCenteredTitle ? "center" : "space-between"}
        py={paddingY}
        px={paddingX}
      >
        <Box
          display="flex"
          alignItems={headerTop ? "flex-start" : "center"}
          justifyContent="flex-start"
          gap={8}
          {...(isProfile && isMobile && { flex: "1 1 auto" })}
        >
          {isProfile && buttonVisible && (
            <div
              onClick={
                isLoginUpdated || isPasswordUpdated ? handleLogout : onBack
              }
              style={{
                paddingLeft: "0px",
                paddingRight: "10px",
                marginTop: "5px",
                cursor: "pointer",
                ...(isCenteredTitle &&
                  !isMobile && {
                    position: "absolute",
                    left: 35,
                  }),
              }}
            >
              {isMobile ? <ArrowLeft width="8" height="14" /> : <ArrowLeft />}
            </div>
          )}
          {isMobile && <Title />}
        </Box>
        {!isMobile && <Title />}

        {/*  X button */}
        {!isMobile && !isCenteredTitle && (
          <Box
            sx={{
              cursor: "pointer",
              alignSelf: isProfile && !headerTop ? "center" : "flex-start",
              width: "24px",
              height: "24px",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={
              isLoginUpdated || isPasswordUpdated ? handleLogout : onBack
            }
          >
            <IconClose
              width={isProfile ? 18 : 24}
              height={isProfile ? 18 : 24}
              color="var(--main-color-text-title)"
            />
          </Box>
        )}
      </Box>

      {/* Body */}
      {children}
    </DialogContainer>
  );
};
