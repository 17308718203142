import { useState } from "react";
import ReplenishImg from "images/replenish.png";
import TransferImg from "images/transfer.png";
import { systemActions } from "store/slices/system";
import { AnotherPages } from "store/slices/system/slice";
import { IconArrow } from "atoms/IconArrowTransfer";
import { RefillModal } from "components/refillModal/refillModal";
import { refillAction } from "utils/refillAction";
import { SHOW_REFILL } from "utils/constants";
import { setIsOpenModal } from "store/slices/transferSlice";
import {
  CardImg,
  IconWrapper,
  TransferBtn,
  TransferBtnContainer,
  TransferBtnText,
} from "./components";
import { useDispatch } from "store";

const TransfersHub = () => {
  const dispatch = useDispatch();
  const [refillOpen, setRefillOpen] = useState(false);
  const [successRefill, setSuccessRefill] = useState(true);

  const goToHandler = (tab: AnotherPages) => {
    dispatch(setIsOpenModal(false));
    dispatch(systemActions.setActiveTab({ tab }));
  };

  const goToRefill = async () => {
    setRefillOpen(true);
    try {
      const result = await refillAction(dispatch);
      if (result.success) {
        setSuccessRefill(true);
      }
    } catch (error) {
      setSuccessRefill(false);
    }
  };

  return (
    <>
      <TransferBtnContainer>
        <TransferBtn onClick={() => goToHandler(AnotherPages.transfers)}>
          <IconWrapper>
            <IconArrow rotate={45} />
          </IconWrapper>
          <CardImg src={TransferImg} />
          <TransferBtnText>Перевести</TransferBtnText>
        </TransferBtn>

        {SHOW_REFILL && (
          <TransferBtn onClick={goToRefill}>
            <IconWrapper>
              <IconArrow rotate={180} />
            </IconWrapper>
            <CardImg src={ReplenishImg} />
            <TransferBtnText>Пополнить</TransferBtnText>
          </TransferBtn>
        )}
      </TransferBtnContainer>

      {/* Refill modal */}
      <RefillModal
        isOpen={refillOpen}
        onClose={() => setRefillOpen(false)}
        isSocces={successRefill}
      />
    </>
  );
};

export default TransfersHub;
