import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatMessageDto, GroupedChatMessages } from "api/notification";
import { getChatMessages, sendChatMessage } from "./asyncThunks";
import { ErrorResponse } from "api/auth";
import { format } from "date-fns";
import { logout } from "../auth";
import { cloneDeep } from "lodash";

export type ChatState = {
  isChatOpened: boolean;
  messages: Record<string, ChatMessageDto[]>;
  size: GroupedChatMessages["size"];
  page: GroupedChatMessages["page"];
  latestPage: number | null;
  paginationPage: number | null;
  isLoading: boolean;
  chatMessagesState: "initial" | "scrolling" | "firstMessagesLoaded";
  error: ErrorResponse | null;
  isImageViewerShown: boolean;
  selectedImage: string | null;
  files: { id?: string; file: File }[];
  unavailableError: ErrorResponse | null;
  isSizeError: boolean;
  isSizeErrorVisible: boolean;
  isMessageSending: boolean;
  initSumMessages: number;
  chatMessageCounter: number;
  newMessageCounter: number;
  chatError: boolean;
  showOperatorRating: boolean;
  operatorRatingTitle: string;
  idRatingMessage: string;
};

const initialState: ChatState = {
  isChatOpened: false,
  messages: {},
  isLoading: false,
  error: null,
  latestPage: null,
  paginationPage: null,
  chatMessagesState: "initial",
  isImageViewerShown: false,
  selectedImage: null,
  size: 0,
  page: 0,
  files: [],
  unavailableError: null,
  isSizeError: false,
  isSizeErrorVisible: false,
  isMessageSending: false,
  initSumMessages: 0,
  chatMessageCounter: 0,
  newMessageCounter: Number(localStorage.getItem("lastKnownNewMessageCount")),
  chatError: true,
  showOperatorRating: false,
  idRatingMessage: "",
  operatorRatingTitle: "",
};

// Максимальный размер файла 5MB
const MAX_FILE_SIZE = 5242880;

const chatSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setChatMessageCounter: (state) => {
      state.chatMessageCounter++;
    },
    clearChatMessageCounter: (state) => {
      state.chatMessageCounter = 0;
    },
    setIsChatOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isChatOpened = payload;
    },
    setLatestPage: (state, { payload }: PayloadAction<number>) => {
      state.latestPage = payload;
    },
    setPaginationPage: (state, { payload }: PayloadAction<number>) => {
      state.paginationPage = payload;
    },
    setChatMessageState: (
      state,
      { payload }: PayloadAction<"initial" | "scrolling">
    ) => {
      state.chatMessagesState = payload;
    },
    setMessages: (
      state,
      { payload }: PayloadAction<Record<string, ChatMessageDto[]>>
    ) => {
      Object.entries(payload).forEach(([day, messages]) => {
        state.messages[day] = state.messages[day] || [];
        const existingIds = new Set(state.messages[day].map((mes) => mes.id));
        const newMessages = messages.filter(
          (message) => !existingIds.has(message.id)
        );
        state.messages[day] = [...state.messages[day], ...newMessages];
      });
    },
    setReadStatus: (state) => {
      Object.entries(state.messages).forEach(([day, messages]) => {
        state.messages[day] = messages.map((message) =>
          message.read === false ? { ...message, read: true } : message
        );
      });
      state.newMessageCounter = 0;
      localStorage.removeItem("lastKnownNewMessageCount");
    },
    setShowImageViewer: (state, { payload }: PayloadAction<boolean>) => {
      state.isImageViewerShown = payload;
    },
    setSelectedImage: (state, { payload }: PayloadAction<string>) => {
      state.selectedImage = payload;
    },
    clearIsFileWithErrorSize: (state) => {
      state.isSizeError = false;
    },
    setMessage: (
      state,
      { payload }: PayloadAction<ChatMessageDto & { isError?: boolean }>
    ) => {
      const formattedDate = format(
        new Date(payload.createdAt || ""),
        "dd.MM.yyyy"
      );
      if (state.messages[formattedDate]) {
        state.messages[formattedDate] = [
          ...state.messages[formattedDate],
          payload,
        ];
      } else {
        state.messages[formattedDate] = [payload];
      }
    },
    setPaginationMessages: (
      state,
      { payload }: PayloadAction<Record<string, ChatMessageDto[]> | null>
    ) => {
      if (payload) {
        Object.entries(payload).forEach(([day, messages]) => {
          state.messages[day] = state.messages[day] || [];
          const existingIds = new Set(state.messages[day].map((mes) => mes.id));
          const newMessages = messages.filter(
            (message) => !existingIds.has(message.id)
          );
          state.messages[day] = [...newMessages, ...state.messages[day]];
        });
      }
    },
    setTimeoutMessages: (
      state,
      {
        payload,
      }: PayloadAction<{
        size?: number;
        messages: Record<string, ChatMessageDto[]>;
      }>
    ) => {
      if (payload) {
        let targetState = {};
        for (const day in payload.messages) {
          if (state.messages[day]) {
            let stateMessagesByDay = cloneDeep(state.messages[day]);
            const cleanStateMessages = stateMessagesByDay.filter(
              (stateMessage) =>
                payload.messages[day].findIndex(
                  (payloadMessage) => payloadMessage.id === stateMessage.id
                ) === -1
            );
            let combinedMessageArray = cleanStateMessages.concat(
              payload.messages[day]
            );
            const sortedCombinedMessageArray = combinedMessageArray.sort(
              //@ts-ignore
              (m1, m2) => {
                return (
                  //@ts-ignore
                  new Date(m1.createdAt).getTime() -
                  //@ts-ignore
                  new Date(m2.createdAt).getTime()
                );
              }
            );
            targetState[day] = sortedCombinedMessageArray;
          } else {
            targetState[day] = payload.messages[day];
          }

          let stateMessagesCopy = cloneDeep(state.messages);
          let resultState = { ...stateMessagesCopy, ...targetState };
          state.messages = resultState;
        }
        if (payload.size === 20) {
          state.latestPage = (state.latestPage || 0) + 1;
        }
      }
    },
    addFile: (
      state,
      { payload }: PayloadAction<{ id?: string; file: File }>
    ) => {
      if (payload.file.size < MAX_FILE_SIZE) {
        state.isSizeError = false;
        state.isSizeErrorVisible = false;
        state.files.push(payload);
      } else {
        state.isSizeError = true;
        state.isSizeErrorVisible = true;
      }
    },
    removeFile: (state, { payload }: PayloadAction<string>) => {
      state.files = state.files.filter(({ id }) => id !== payload);
    },
    clearFiles: (state) => {
      state.files = [];
    },
    clearState: () => initialState,
    setIsSizeErrorClosed: (state) => {
      state.isSizeErrorVisible = false;
    },
    setInitSumMessages: (state, { payload }: PayloadAction<number>) => {
      state.initSumMessages += payload;
    },
    setNewMessageCounter: (state, { payload }: PayloadAction<number>) => {
      state.newMessageCounter = payload;
    },
    clearUnavailableError: (state) => {
      state.unavailableError = null;
    },
    setShowOperatorRating: (state, { payload }) => {
      state.showOperatorRating = payload;
    },
    setIdRatingMessage: (state, { payload }) => {
      state.idRatingMessage = payload;
    },
    setOperatorRatingTitle: (state, { payload }) => {
      state.operatorRatingTitle = payload;
    },
  },
  extraReducers: {
    [getChatMessages.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = null;
      }
    },
    [getChatMessages.fulfilled.type]: (
      state,
      { payload }: PayloadAction<GroupedChatMessages>
    ) => {
      state.isLoading = false;
      state.page = payload.page;
      state.messages = { ...payload.messages, ...state.messages };
      state.size = payload.size;
      state.chatError = true;

      if (state.chatMessagesState === "initial") {
        state.chatMessagesState = "firstMessagesLoaded";
      }
    },
    [getChatMessages.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response?.data || undefined;
      state.chatError = false;
    },
    [sendChatMessage.pending.type]: (state) => {
      state.isMessageSending = true;
    },
    [sendChatMessage.fulfilled.type]: (state) => {
      state.isMessageSending = false;
      state.unavailableError = null;
      state.error = null;
    },
    [sendChatMessage.rejected.type]: (state, { payload }) => {
      state.isMessageSending = false;
      if (payload?.response?.status === 406) {
        state.unavailableError = payload?.response?.data;
      }
      state.error = payload?.response?.data;
    },
    [logout.fulfilled.type]: (state) => initialState,
  },
});

export default chatSlice;
