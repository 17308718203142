import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cardLimitsApi } from "../../api";
import { IFourHundredErr } from "api/types";
import {
  CardLimitConfirmResponse,
  CardLimitRequest,
  CardLimitUpdateConfirmRequest,
  CardLimitUpdateRequest,
} from "../../api/account";

export type LimitState = {
  error: IFourHundredErr["response"]["data"];
  isLoading: boolean;
  limits: Array<CardLimitConfirmResponse>;
  limitToken: string | undefined;
};

const initialState: LimitState = {
  error: {
    code: "",
    comment: "",
    id: 0,
    subtitle: "",
    title: "",
  },
  isLoading: false,
  limits: [],
  limitToken: "",
};

export const getLimits = createAsyncThunk(
  "getLimits",
  async (payload: CardLimitRequest, { rejectWithValue }) => {
    try {
      const response = await cardLimitsApi.getLimitsInformation(
        "string",
        payload
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateLimits = createAsyncThunk(
  "updateLimits",
  async (payload: CardLimitUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await cardLimitsApi.updateCardLimit("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCardLimitConfirm = createAsyncThunk(
  "updateCardLimitConfirm",
  async (payload: CardLimitUpdateConfirmRequest, { rejectWithValue }) => {
    try {
      const response = await cardLimitsApi.updateCardLimitConfirm(
        "string",
        payload
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const limitsSlice = createSlice({
  name: "limits",
  initialState,
  reducers: {
    setNewLimit: (state, { payload }) => {
      let limit = state.limits.filter((l) => l.limitId === payload.limitId);
      limit[0].limitSumAmount = payload.limitSumAmount;
    },
    clearError: (state) => {
      state.error = {
        code: "",
        comment: "",
        id: 0,
        subtitle: "",
        title: "",
      };
    },
    resetStore: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLimits.fulfilled, (state, action) => {
      state.limits = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getLimits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLimits.rejected, (state) => {
      state.error = {
        code: "LIMIT_ERROR",
        comment: "",
        id: 0,
        subtitle: "Сервис временно недоступен, попробуйте зайти позже",
        title: "Ошибка загрузки лимитов",
      };
      state.isLoading = false;
    });
    builder.addCase(updateLimits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLimits.fulfilled, (state, action) => {
      state.limitToken = action?.payload?.updateLimitToken;
      state.isLoading = false;
    });
    builder.addCase(updateLimits.rejected, (state, { payload }) => {
      state.error = (payload as IFourHundredErr).response.data;
      state.isLoading = false;
    });
  },
});

export const limitsActions = limitsSlice.actions;
export const { setNewLimit, clearError } = limitsSlice.actions;
export default limitsSlice.reducer;
