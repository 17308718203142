/* eslint-disable no-inline-comments */
export const validateAmount = (string: string) => {
  const value = string
    .replace(",", ".") // Заменяем запятую на точку
    .replace(/[^0-9.]/g, "") // Удаляем все символы, кроме цифр и точки
    .replace(/(\..*)\./g, "$1"); // Убираем повторяющиеся точки

  const sanitizedValue =
    value.startsWith("0") && // Проверяем, начинается ли строка с нуля
    value !== "0" && // И значение не равно "0"
    !value.startsWith("0.") // И значение не начинается с "0."
      ? value.replace(/^0+/, "0") // Заменяем все начальные нули на один "0"
      : value; // Если условия не выполняются, оставляем значение как есть

  const result =
    sanitizedValue === "" || isNaN(Number(sanitizedValue)) // Если строка пустая или не является числом
      ? undefined // Возвращаем undefined
      : Number(sanitizedValue); // Иначе преобразуем строку в число

  return result;
};
