import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { LoginInput } from "molecules/LoginInput/LoginInput";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { authSelector, useDispatch, useSelector } from "store";
import { loginPasswordPattern, loginPattern } from "constants/regexp";
import { authActions, login } from "../../store/slices/auth";
import { UserStatus } from "./../../store/slices/auth/model";
import { BlockedScreen } from "./BlockedScreen";
import { UnBlockedScreen } from "./UnBlockedScreen";
import { BlockedScreenPinChange } from "./BlockedScreenPinChange";
import { showErrorMessage } from "../../store/slices/alerts";
import { useIsPWA } from "hooks/useIsPWA";
import { deleteFCMToken } from "../../firebase";
import { ForgotHint } from "components/ForgotHint";
import { Button } from "components/Button";
import { useIsMobile } from "hooks/useIsMobile";
import { LOGIN_ERROR_CODE } from "store/slices/auth/slice";
import styles from "./style.module.css";

const ignoredErrorCodes = [
  LOGIN_ERROR_CODE.CLIENT_BLOCKED,
  LOGIN_ERROR_CODE.CLIENT_UNBLOCKED,
];

const ignoredMobileErrorCodes = [
  LOGIN_ERROR_CODE.OTP_BLOCKED,
  LOGIN_ERROR_CODE.ACCOUNT_LOCKED_BY_AUTH_ATTEMPTS,
  LOGIN_ERROR_CODE.WRONG_REQUEST,
];

export const Authorization = () => {
  const [isShowForgotHint, setIsShowForgotHint] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPWA = useIsPWA();
  const { isMobile } = useIsMobile();

  const [values, setValues] = useState({
    login: "",
    password: "",
  });

  const {
    isLoading,
    error,
    elseDevice,
    isAccountBlocked,
    isLoginBlocked,
    accountStatus,
  } = useSelector(authSelector);

  const handleClickToMain = () => {
    if (localStorage.getItem("isPushOn")) {
      deleteFCMToken();
      localStorage.removeItem("isPushOn");
    }

    dispatch(
      login({
        login: values.login,
        password: values.password,
        isPWA,
      })
    )
      .unwrap()
      .then(() => {
        if (elseDevice) {
          localStorage.setItem("elseLogin", values.login);
        } else {
          localStorage.setItem("tmpLogin", values.login);
        }
        navigate("/confirm", { replace: true });
      })
      .catch((err) => {
        if (err.response.data.code === "WRONG_DEVICE") {
          dispatch(
            authActions.setAnotherDeviceError({
              title: err.response.data.title,
              description: err.response.data.subtitle,
            })
          );
          navigate("/login-error");
        } else {
          if (
            [...ignoredErrorCodes, ...ignoredMobileErrorCodes].includes(
              err.response.data.code
            )
          )
            return;

          dispatch(
            showErrorMessage({
              errorTitle: err.response.data.title,
              errorMessage: err.response.data.subtitle,
            })
          );
        }
      });
  };

  const handleChange = (e) => {
    if (error) dispatch(authActions.resetError());
    const { name, value } = e.target;
    const pattern = name === "login" ? loginPattern : loginPasswordPattern;

    setValues({
      ...values,
      [e.target.name]: value.replace(pattern, ""),
    });
  };

  if (isShowForgotHint) {
    return <ForgotHint handleBack={() => setIsShowForgotHint(false)} />;
  }

  if (isAccountBlocked) {
    return <BlockedScreen />;
  }

  if (accountStatus === UserStatus.UNBLOCKED) {
    return <UnBlockedScreen />;
  }

  if (isLoginBlocked) {
    return <BlockedScreenPinChange />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <p className={styles.title}>Войти</p>
        {ignoredMobileErrorCodes.includes(error?.code) && (
          <p
            className={styles.error}
            style={{ textAlign: isMobile ? "center" : "left" }}
          >
            {error.title}. {error.subtitle}
          </p>
        )}
        <form autoComplete="off">
          <LoginInput
            name="login"
            value={values.login}
            placeholder="Введите логин"
            onChange={handleChange}
            error={error ? "" : null}
            sx={{ mb: 12 }}
          />
          <PasswordInput
            value={values.password}
            placeholder="Введите пароль"
            onChange={handleChange}
            name="password"
            error={error ? "" : null}
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={40}
            rowGap={12}
          >
            <Button
              variant="primary"
              onClick={handleClickToMain}
              isLoading={isLoading}
              title="Войти"
            />
            <Button
              variant="secondary"
              onClick={() => setIsShowForgotHint(true)}
              title="Не помню логин или пароль"
            />
            <Button
              variant="text"
              title="Зарегистрироваться"
              onClick={() => navigate("/sign-up")}
            />
          </Box>
        </form>
      </div>
    </div>
  );
};
