import { LimitType } from "api/account";

export const rusSubtitle = (subtitle: string) => {
  switch (subtitle) {
    case LimitType.Daily:
      return "Суточный лимит";
    case LimitType.Weekly:
      return "Еженедельный лимит на";
    case LimitType.Monthly:
      return "Месячный лимит на";
    case LimitType.Quarterly:
      return "Квартальный лимит на";
    case LimitType.Yearly:
      return "Годовой лимит на";
  }
};
