import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import Notifications from "organisms/Notifications/Notifications";
import {
  Container,
  Tab,
  TabText,
  TabWrapper,
} from "pages/home/Cards/History/components/HistoryTabs/components";
import News from "organisms/Notifications/News";
import { notificationSelector, useSelector } from "store";

export enum NOTIFICATION_TABS {
  NOTIFICATIONS = "Уведомления",
  NEWS = "Новости",
}

const TABS = Object.values(NOTIFICATION_TABS);

export const NotificationNewsModal = () => {
  const { newNews } = useSelector(notificationSelector);
  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS.NOTIFICATIONS);

  const renderContent = useCallback(() => {
    switch (activeTab) {
      case NOTIFICATION_TABS.NOTIFICATIONS:
        return <Notifications />;
      case NOTIFICATION_TABS.NEWS:
        return <News />;
      default:
        return null;
    }
  }, [activeTab]);

  return (
    <Box display="flex" flexDirection="column" my={12}>
      <Container marginTop={false}>
        <Box px={{ xs: "16px", lg: "32px" }} width="100%">
          <TabWrapper
            style={{
              justifyContent: "space-between",
              gap: "4px",
              flex: "1 auto",
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab}
                active={activeTab === tab}
                onClick={() => {
                  if (activeTab === tab) return;
                  setActiveTab(tab);
                }}
                width="100%"
              >
                <TabText active={activeTab === tab}>{tab}</TabText>
                {newNews && tab === NOTIFICATION_TABS.NEWS && (
                  <Box
                    width="8px"
                    height="8px"
                    borderRadius="50%"
                    bgcolor="#DEB16E"
                    ml="5px"
                    mt="-11px"
                  />
                )}
              </Tab>
            ))}
          </TabWrapper>
        </Box>
      </Container>
      {renderContent()}
    </Box>
  );
};
