import Modal from "@mui/material/Modal";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { formatAmount } from "utils/formatCurrency";
import { IconLoginChangeWarning } from "atoms/IconLoginChangeWarning/IconLoginChangeWarning";
import { Button } from "components/Button";
import { AlertModalMobile } from "components/AlertModalMobile";
import { useSelector } from "store";
import styled from "@emotion/styled";

type Props = {
  id: number;
  subtitle: string;
  title: string;
  selected: boolean;
  onClick: (id: number) => void;
  limitCurrentAmount: string;
  limitSumAmount: string;
  readOnly: boolean;
  svg: null | (() => JSX.Element);
  isFirst?: boolean;
};

export const LimitItem = ({
  id,
  title,
  onClick,
  svg = null,
  limitCurrentAmount,
  limitSumAmount,
  readOnly,
  isFirst = false,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useIsMobile();
  const {
    cards: {
      mainCard,
      account: { currency },
    },
  } = useSelector((state) => state);
  const [activeIndexItem, setActiveIndexItem] = useState<number | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const isAbroadLimit = id === 3050;
  const limitText = isAbroadLimit
    ? "получение иностранной валюты"
    : title.toLocaleLowerCase();

  const handleClick = () => {
    if (mainCard.status === "ACTIVE") onClick(id);
  };

  const remainder = formatAmount(
    Number(limitSumAmount) - Number(limitCurrentAmount),
    currency?.sign,
    true
  );

  return (
    <Box
      sx={{
        borderBottomWidth: 2,
        borderBottomStyle: isFirst ? "solid" : "",
        borderBlockColor: isFirst ? "var(--main-color-bg-secondary)" : "",
      }}
    >
      <Box
        pb={isFirst ? 20 : 12}
        pt={isFirst ? 0 : 12}
        px={isFirst ? 0 : 12}
        display="flex"
        alignItems="center"
        bgcolor={
          activeIndexItem === id || isFirst
            ? ""
            : "var(--main-color-bg-widgets)"
        }
        borderRadius="16px"
        sx={{
          cursor: "pointer",
        }}
        onClick={readOnly ? () => setShowAlert(true) : handleClick}
        onMouseEnter={() => setActiveIndexItem(id)}
        onMouseLeave={() => setActiveIndexItem(null)}
      >
        <IconButton
          sx={{
            mr: 12,
            backgroundColor:
              activeIndexItem === id || isFirst
                ? "var(--main-color-bg-widgets)"
                : "var(--main-color-white)",
            borderRadius: isMobile ? 20 : 8,
            width: isMobile ? 36 : 40,
            height: isMobile ? 36 : 40,
            alignSelf: isFirst ? "flex-start" : "center",
          }}
        >
          {svg && svg()}
        </IconButton>
        <Box display="flex" flexDirection="column">
          <Typography variant="text_5" color={theme.primary.main.gray["800"]}>
            {title}
          </Typography>
          <Typography
            variant="text_3"
            color={theme.primary.main.gray["400"]}
            mt={4}
          >
            Осталось{" "}
            {remainder.startsWith("-") ? `0 ${currency?.sign}` : remainder}
          </Typography>
        </Box>
        {!readOnly && (
          <Box sx={{ ml: "auto" }}>
            <IconChevronRight color="var(--main-color-text-title)" />
          </Box>
        )}
      </Box>
      {isMobile ? (
        <AlertModalMobile
          isOpen={showAlert}
          handler={() => setShowAlert(false)}
          title={`Лимит на ${limitText} изменить нельзя`}
        />
      ) : (
        <Modal open={showAlert} onClose={() => setShowAlert(false)}>
          <Alert>
            <AlertContent>
              <IconLoginChangeWarning />
              <AlertTitle>Лимит на {limitText} изменить нельзя</AlertTitle>
              <AlertButtonsBlock>
                <Button
                  variant="primary"
                  onClick={() => setShowAlert(false)}
                  title="Понятно"
                />
              </AlertButtonsBlock>
            </AlertContent>
          </Alert>
        </Modal>
      )}
    </Box>
  );
};

const Alert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AlertContent = styled.div`
  padding: 32px 32px 52px;
  border-radius: 24px;
  background: var(--main-color-white);
  width: 100%;
  max-width: 464px;
  text-align: center;

  @media (max-width: 767px) {
    max-width: 300px;
  }
`;

const AlertTitle = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: var(--main-color-text-title);
  text-align: center;
  margin: 12px 0;
`;

const AlertButtonsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 40px;
`;
