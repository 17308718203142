import { FC, useState } from "react";
import {
  GetListRequestOperationFilterTypeEnum,
  StatementType,
  OperationFilterType,
} from "api/account";
import { SearchFiltersValues } from "pages/home/Cards/History/History";
import {
  Container,
  DropdownItem,
  DropdownTitle,
  DropdownTitleAnchored,
  DropdownWrapper,
} from "./components";
import { useOutsideClick } from "hooks/useOutsideClick";
import { HISTORY_TABS, setActiveCategory } from "store/slices/historySlice";
import { useDispatch, useSelector } from "store";
import { IconCheck } from "atoms/IconCheck/IconCheck";
import IconCaret from "atoms/IconCaret/IconCaret";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";

const OperationTypeMapAll = {
  [GetListRequestOperationFilterTypeEnum.Cash]: "Наличные",
  [GetListRequestOperationFilterTypeEnum.Goods]: "Покупки и платежи",
  [GetListRequestOperationFilterTypeEnum.WriteOffs]: "Списания",
  [GetListRequestOperationFilterTypeEnum.Incomes]: "Зачисления",
  [GetListRequestOperationFilterTypeEnum.Transfers]: "Переводы",
};

const OperationTypeMapExpense = {
  [StatementType.CashOut]: "Снятие наличных",
  [GetListRequestOperationFilterTypeEnum.Goods]: "Покупки и платежи",
  [StatementType.TransferOut]: "Исходящие переводы",
  [OperationFilterType.Commission]: "Комиссии",
  [OperationFilterType.WriteOffsOther]: "Прочие списания",
};

const OperationTypeMapIncome = {
  [StatementType.CashIn]: "Внесение наличных",
  [StatementType.TransferIn]: "Входящие переводы",
  [OperationFilterType.Refund]: "Возврат",
  [OperationFilterType.IncomesOther]: "Прочие доходы",
};

const data = {
  [HISTORY_TABS.ALL_OPERATIONS]: OperationTypeMapAll,
  [HISTORY_TABS.EXPENSES]: OperationTypeMapExpense,
  [HISTORY_TABS.INCOMES]: OperationTypeMapIncome,
};

interface DropdownItem {
  id: GetListRequestOperationFilterTypeEnum | "";
  title: string;
}

interface DropdownProps {
  items: Array<DropdownItem>;
  onChange: (value: DropdownItem) => void;
  value: GetListRequestOperationFilterTypeEnum | "";
}

const Dropdown = ({ items, onChange, value }: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeDropdown = () => setOpen(false);
  const rootElement = useOutsideClick(closeDropdown);
  const { activeTab } = useSelector((state) => state.history);
  const ALL_OPERATIONS =
    activeTab === HISTORY_TABS.ALL_OPERATIONS ? "Все операции" : "Все";

  const clickHandler = (item: DropdownItem) => {
    onChange(item);
    closeDropdown();
  };

  const onClear = (e) => {
    e.stopPropagation();
    clickHandler({
      id: "",
      title: "",
    });
  };

  return (
    <Container ref={rootElement}>
      {data[activeTab][value] ? (
        <DropdownTitleAnchored
          onClick={() => setOpen((prevState) => !prevState)}
        >
          {data[activeTab][value]}
          <IconCircleClose
            style={{ cursor: "pointer", marginLeft: "6px" }}
            onClick={onClear}
          />
        </DropdownTitleAnchored>
      ) : (
        <DropdownTitle
          style={{
            color: open
              ? "var(--main-color-notification-description)"
              : "var(--main-color-text-title)",
          }}
          onClick={() => setOpen((prevState) => !prevState)}
        >
          {data[activeTab][value] || "Все"}
          <IconCaret
            style={{
              marginLeft: 10,
              rotate: open ? "180deg" : "0deg",
              transition: "rotate 0.2s ease-out",
            }}
            color={
              open
                ? "var(--brand-color-primary)"
                : "var(--main-color-text-title)"
            }
          />
        </DropdownTitle>
      )}

      {open && (
        <DropdownWrapper style={{ width: "342px" }}>
          {items.map((item) => {
            return (
              <DropdownItem
                key={item.id}
                style={{
                  color:
                    data[activeTab][value] === item.title ||
                    (!value && item.title === ALL_OPERATIONS)
                      ? "var(--main-color-notification-description)"
                      : "var(--main-color-text-title)",
                }}
                onClick={() => clickHandler(item)}
              >
                {item.title}
                {(data[activeTab][value] === item.title ||
                  (!value && item.title === ALL_OPERATIONS)) && (
                  <IconCheck
                    style={{ marginLeft: 10 }}
                    width={18}
                    height={18}
                  />
                )}
              </DropdownItem>
            );
          })}
        </DropdownWrapper>
      )}
    </Container>
  );
};

interface OperationTypeProps {
  value: GetListRequestOperationFilterTypeEnum | "";
  onChange: (values: Partial<SearchFiltersValues>) => void;
}

export const OperationType: FC<OperationTypeProps> = ({ onChange, value }) => {
  const { activeTab } = useSelector((state) => state.history);
  const dispatch = useDispatch();

  const ALL_OPERATIONS =
    activeTab === HISTORY_TABS.ALL_OPERATIONS ? "Все операции" : "Все";

  const operationTypes = Object.keys(data[activeTab]).map((key) => ({
    id: key,
    title: data[activeTab][key],
  }));

  const handleChange = (value: DropdownItem) => {
    onChange({ operationFilterType: value.id });
    dispatch(setActiveCategory(null));
  };

  return (
    <Dropdown
      // @ts-ignore
      items={[{ id: "", title: ALL_OPERATIONS }, ...operationTypes]}
      onChange={(value) => handleChange(value)}
      value={value}
    />
  );
};
