import { Box } from "@mui/material";
import { AccountCard } from "./AccountCard";
import { ConnectedCards } from "./ConnectedCards";
import { useSelector } from "store";

export const AccountInfo = () => {
  const {
    cards: {
      account: { availableBalance, currency, description, accountNumber },
      allCards,
    },
  } = useSelector((state) => state);

  return (
    <Box display="flex" flexDirection="column">
      <AccountCard
        availableBalance={availableBalance}
        currency={currency}
        description={description}
        accountNumber={accountNumber}
      />
      {allCards.length > 0 && <ConnectedCards cards={allCards} />}
    </Box>
  );
};
