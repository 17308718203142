import { Box, Typography, useTheme } from "@mui/material";
import { Modal } from "molecules/Modal/Modal";
import { SkeletonContainer } from "components";
import { useEffect, useMemo } from "react";
import {
  getHistoryItem,
  setShowMobileFinancialAnalysisPage,
} from "store/slices/historySlice";
import { formatCurrency } from "utils/formatCurrency";
import { IncomeIconWrapper } from "./components/OperationItem";
import { Cage } from "components/Icons/historyOperationIcons/Cage";
import { OtherOut } from "components/Icons/historyOperationIcons/OtherOut";
import { TransferIn } from "components/Icons/historyOperationIcons/TransferIn";
import { Wallet } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { historySelector, useDispatch, useSelector } from "store";
import { systemActions } from "store/slices/system";
import { getReport, repeatTransfer } from "store/slices/transferSlice";
import { FileType, downloadFile } from "utils/downloadFile";
import { Button } from "components/Button";
import { useIsMobile } from "hooks/useIsMobile";
import { IconCardTranferCheck } from "atoms/IconCardTranferCheck";
import { format, utcToZonedTime } from "date-fns-tz";
import { ru } from "date-fns/locale";
import { setChosenCardIndex } from "store/slices/cardsSlice";
import { AnotherPages } from "store/slices/system/slice";
import IconRefreshReverse from "components/Icons/IconRefreshReverse";
import IconArrowOutcoming from "atoms/IconArrowOutcoming/IconArrowOutcoming";
import IconArrowIncoming from "atoms/IconArrowIncoming/IconArrowIncoming";
import IconDownloadCheck from "atoms/IconDownloadCheck";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  historyId: string;
};

const useStyles = makeStyles(() => ({
  wrap: {
    height: "56px",
    width: "56px",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    background: "white",
  },
}));

enum IconType {
  TransferOut = "transfer-out",
  Payment = "payment",
  CashIn = "cash-in",
  CashOut = "cash-out",
  CashInExternal = "cash-in-external",
  TransferIn = "transfer-in",
  Reverse = "reverse",
  OtherOut = "other-out",
  OtherIn = "other-in",
  Other = "other",
  Salary = "salary",
  CashBack = "cash-back",
  CashBackOut = "cash-back-out",
}

export const HistoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  historyId,
}) => {
  const {
    history: { historyItem, showMobileFinancialAnalysisPage },
    cards: { allCards },
  } = useSelector((state) => state);
  const isCanceled = historyItem?.status === "error";
  const canBeRepeated = historyItem?.canBeRepeated;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isItemLoading } = useSelector(historySelector);
  const { blue, red } = theme.palette;
  const { isMobile } = useIsMobile();
  const isMinusOperation = String(historyItem?.amount).startsWith("-");

  const transferDetails = useMemo(
    () => [
      ...(isMinusOperation
        ? [
            { title: "Карта списания", value: historyItem?.pan },
            {
              title: "Получатель",
              // @ts-ignore
              value: historyItem?.toClientName || "не указано",
            },
          ]
        : []),
      { title: "Номер карты получателя", value: historyItem?.receiver },
      { title: "Сумма перевода", value: historyItem?.amount },
      { title: "Дата и время", value: historyItem?.time },
      { title: "Сообщение", value: historyItem?.reason },
    ],
    [historyItem, isMinusOperation]
  );

  const operationStatus = (status: string) => {
    if (status !== "error") {
      return "Операция выполнена";
    } else {
      return "Операция отклонена";
    }
  };

  const renderPreviewIcon = (icon: IconType) => {
    const fillColor = isCanceled ? red.b400 : blue.b400;
    switch (icon) {
      case "transfer-out":
      case IconType.CashBackOut:
        return (
          <IncomeIconWrapper bgColor="white" style={{ padding: 10 }}>
            <IconArrowOutcoming />
          </IncomeIconWrapper>
        );
      case "payment":
        return <Cage fill={fillColor} />;
      case "cash-in":
        return <OtherOut fill={fillColor} />;
      case "cash-out":
        return <TransferIn fill={fillColor} />;
      case "transfer-in":
      case IconType.CashBack:
        return (
          <IncomeIconWrapper
            bgColor="var(--brand-color-disabled)"
            style={{ padding: 10 }}
          >
            <IconArrowIncoming />
          </IncomeIconWrapper>
        );

      case "cash-in-external":
      case "reverse":
      case "other-out":
      case "other-in":
      case "other":
        return <Wallet fill={fillColor} />;
      case "salary":
      default:
        return <Wallet fill={fillColor} />;
    }
  };

  const formatValue = (title, value) => {
    switch (title) {
      case "Дата и время": {
        const zonedDate = utcToZonedTime(value, "Europe/Moscow");
        return format(zonedDate, "d MMMM yyyy, HH:mm", { locale: ru });
      }
      case "Сумма перевода":
      case "Комиссия":
        return formatCurrency(value, true);
      default:
        return value;
    }
  };

  const repeatPayment = () => {
    dispatch(repeatTransfer(historyItem?.statementId as string))
      .unwrap()
      .then((res) => {
        if (showMobileFinancialAnalysisPage) {
          dispatch(setShowMobileFinancialAnalysisPage(false));
        }
        dispatch(
          systemActions.setActiveTab({
            tab: AnotherPages.transfers,
            state: res,
          })
        );
        const cardIndex = allCards.findIndex(
          (card) => card.cardId === historyItem?.cardId
        );
        dispatch(setChosenCardIndex(cardIndex));
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  const downloadCheck = () => {
    if (historyItem) {
      dispatch(getReport(historyItem.statementId as string))
        .unwrap()
        .then((res) => {
          downloadFile(res, "check.pdf", FileType.PDF);
        });
    }
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getHistoryItem(historyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!historyItem) return null;

  return (
    // @ts-ignore
    <Modal
      title={operationStatus(historyItem?.status as string)}
      isOpen={isOpen}
      onClose={onClose}
      callback={onClose}
      isProfile
      buttonVisible={isMobile}
    >
      <Box flexGrow="1" paddingX={{ xs: "16px", lg: "32px" }}>
        <Box>
          <SkeletonContainer
            height="180px"
            width="100%"
            isLoading={isItemLoading}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                padding: "28px 10px",
                backgroundColor: "var(--main-color-bg-widgets)",
                borderRadius: "9px",
                rowGap: "8px",
              }}
            >
              <Box className={classes.wrap}>
                {historyItem.category_logo ? (
                  <div className={classes.logo}>
                    <img src={historyItem.category_logo} />
                  </div>
                ) : (
                  renderPreviewIcon(historyItem?.type as IconType)
                )}
              </Box>

              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: 500,
                  lineHeight: "36px",
                  color: "#2D3227",
                }}
              >
                {formatValue("Сумма перевода", historyItem?.amount)}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  columnGap: "8px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: "8px",
                    alignItems: "center",
                  }}
                >
                  <IconCardTranferCheck />
                </Box>

                <Typography
                  sx={{
                    fontSize: "17px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#739B67",
                  }}
                >
                  {historyItem?.acquirerGroup}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" width="100%" gap={12} sx={{ my: "19px" }}>
              {canBeRepeated && (
                <Button
                  variant="secondary"
                  startIcon={<IconRefreshReverse />}
                  onClick={repeatPayment}
                  title="Повторить"
                />
              )}

              <Button
                variant="secondary"
                startIcon={<IconDownloadCheck />}
                onClick={() => downloadCheck()}
                title="Справка"
              />
            </Box>
          </SkeletonContainer>
        </Box>

        {!isItemLoading && (
          <>
            <Box
              sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}
            >
              {transferDetails.map(({ title, value }, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                      color: "var(--main-color-text-secondary)",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#2D3227",
                    }}
                  >
                    {formatValue(title, value)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
