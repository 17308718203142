import Modal from "@mui/material/Modal";
import { Button } from "components/Button";
import {
  Container,
  Content,
  DescriptionBlock,
  Description,
  Title,
  Line,
} from "./components";

interface Props {
  isOpen: boolean;
  handler: () => void;
  title: string;
  description?: string;
  buttonText?: string;
}

export const AlertModalMobile = ({
  isOpen,
  handler,
  title,
  description,
  buttonText = "Понятно",
}: Props) => {
  return (
    <Modal open={isOpen}>
      <Container>
        <Content>
          <Title>{title}</Title>
          {description && (
            <DescriptionBlock>
              <Description>{description}</Description>
            </DescriptionBlock>
          )}
          <Line />
          <Button
            onClick={handler}
            title={buttonText}
            variant="text"
            style={{ padding: "12px 0" }}
          />
        </Content>
      </Container>
    </Modal>
  );
};
