/* eslint-disable no-console */

import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestPermission = async () => {
  // Проверяем поддержку API уведомлений
  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  if (!isSupported()) {
    console.error("Push уведомления не поддерживаются на данном устройстве.");

    throw {
      text: "Уведомления не поддерживаются на вашем устройстве",
      isOn: false,
    };
  }

  try {
    if (!(window.Notification && window.Notification.requestPermission)) {
      console.error("Нет доступа к API Notification");

      throw {
        text: "Включите push-уведомления для браузера",
        isOn: false,
        alert: true,
      };
    }

    // Запрашиваем разрешение на уведомления
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.warn("Разрешение на уведомления отклонено пользователем.");

      throw {
        text: "Разрешите браузеру получать уведомления",
        isOn: false,
        alert: true,
      };
    }

    // Регистрируем Service Worker
    const registration = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js"
    );

    // Получаем токен FCM
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });

    if (!token) {
      console.error("Не удалось получить FCM Token.");

      throw {
        text: "Попробуйте ещё раз",
        isOn: false,
      };
    }

    return { text: "успешно", isOn: true, token };
  } catch (error) {
    console.error("Ошибка в процессе запроса разрешений.");

    // Обрабатываем ошибки регистрации
    if (error.name === "AbortError") {
      console.error("Ошибка регистрации Service Worker");

      throw {
        text: "Попробуйте ещё раз",
        isOn: false,
      };
    }

    throw {
      text: "Системная ошибка при запросе разрешения",
      isOn: false,
      alert: true,
    };
  }
};

export const deleteFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    if (currentToken) {
      await deleteToken(messaging);
    }
  } catch (error) {
    console.error("Ошибка при удалении FCM Token");
  }
};

export const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    if (currentToken) {
      return currentToken;
    } else {
      console.warn("FCM Token отсутствует.");
      return null;
    }
  } catch (error) {
    console.error("Ошибка при получении FCM Token");
    return null;
  }
};
