import { Box, Typography } from "@mui/material";

export const ClickableHeader = ({ onBack, title = true }) => (
  <Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: "12px",
      }}
    >
      <Box
        onClick={onBack}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7 17L2 11.5L7 6"
            stroke="#454A3F"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
      {title && (
        <Typography
          sx={{
            width: "100%",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "28px",
            textAlign: "center",
            color: "var(--main-color-text-title)",
          }}
        >
          Перевод по номеру карты
        </Typography>
      )}
    </Box>
  </Box>
);
