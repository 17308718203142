export const INACTIVITY_TIMEOUT = parseInt(
  process.env.REACT_APP_INACTIVITY_TIMEOUT || "600000",
  10
);

export const SHOW_REFILL = process.env.REACT_APP_SHOW_REFILL
  ? Boolean(Number(process.env.REACT_APP_SHOW_REFILL))
  : true;
export const SHOW_VIRTUAL_CARD = process.env.REACT_APP_SHOW_VIRTUAL_CARD
  ? Boolean(Number(process.env.REACT_APP_SHOW_VIRTUAL_CARD))
  : true;
