import { Box, Typography } from "@mui/material";
import { chatActions } from "store/slices/chat";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
import { IconFile } from "atoms/IconFile/IconFile";
import { useDispatch } from "store";

type FilesProps = {
  files: { id?: string; file: File }[];
};

const Files = ({ files }: FilesProps) => {
  const dispatch = useDispatch();

  const handleClick = (id?: string) => () => {
    if (id) {
      dispatch(chatActions.removeFile(id));
      dispatch(chatActions.clearIsFileWithErrorSize());
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={16}>
      {files.map(({ id, file }) => (
        <Box key={id} display="flex" justifyContent="space-between">
          <Box
            display="flex"
            gap={4}
            ml={25}
            alignItems="center"
            overflow="hidden"
          >
            {file.type === "application/pdf" ? (
              <IconFile />
            ) : (
              <img
                src={URL.createObjectURL(file)}
                style={{
                  minWidth: "51px",
                  height: "32px",

                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: "11px",
                }}
              />
            )}
            <Box display="flex" flexDirection="column" overflow="hidden">
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                variant="text_3"
                title={file.name}
                color="var(--main-color-text-title)"
              >
                {file.name}
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={handleClick(id)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <IconCircleClose width={20} height={20} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Files;
