import { FC } from "react";
import { systemSelector, useDispatch, useSelector } from "store";
import { systemActions, TabVariants } from "store/slices/system";
import { AnotherPages, tabLabels } from "store/slices/system/slice";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import styles from "./style.module.css";

const Navigation: FC = () => {
  const dispatch = useDispatch();

  const { activeTab } = useSelector(systemSelector);
  const tabsList = Object.keys(TabVariants) as Array<TabVariants>;

  const setTab = (tab: TabVariants) => {
    switch (tab) {
      case TabVariants.history:
        dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
        dispatch(systemActions.setActiveTab({ tab }));
        break;
      case TabVariants.main:
        dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
        dispatch(systemActions.setActiveTab({ tab }));
        break;
      default:
        dispatch(systemActions.setActiveTab({ tab }));
    }
  };

  const effectiveActiveTab =
    activeTab === AnotherPages.transfers ? TabVariants.transfersHub : activeTab;

  return (
    <div className={styles.tabsContainer}>
      {tabsList.map((key) => (
        <button
          className={`${styles.tab} ${
            key === effectiveActiveTab ? styles.selected : ""
          }`}
          key={key}
          onClick={() => setTab(key)}
        >
          {tabLabels[key]}
        </button>
      ))}
    </div>
  );
};

export default Navigation;
