import { IconButton, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { IconChat } from "atoms/IconChat/IconChat";

import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { IconDocument } from "atoms/IconDocument/IconDocument";
import { IconGlobe } from "atoms/IconGlobe/IconGlobe";

import { IconPhone } from "atoms/IconPhone/IconPhone";

import { useIsMobile } from "hooks/useIsMobile";
import { useMemo } from "react";
import { useDispatch } from "store";

import { MobileTabVariants, systemActions } from "store/slices/system";
import { AnotherPages } from "store/slices/system/slice";
import { downloadPDF } from "utils/downloadPDF";

const getDisplayStyle = (id: number, isDesktop: boolean) => {
  if (isDesktop) {
    return id === 2 ? "none" : "";
  }
  return id === 0 || id === 1 || id === 3 || id === 6 ? "none" : "";
};

const BankContacts = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width:980px)");

  const dataIcon: Array<any> = useMemo(
    () => [
      {
        id: 0,
        title: "+7 (800) 200-45-67",
        href: "tel:+78002004567",
        description: "Бесплатный звонок по РФ",
        svg: <IconPhone />,
      },
      {
        id: 1,
        title: "+7 (812) 603-00-90",
        href: "tel:+78126030090",
        description: "Бесплатный звонок за пределами РФ",
        svg: <IconPhone />,
      },
      {
        id: 2,
        showArrow: true,
        title: "Связь с банком",
        svg: <IconPhone />,
        isFunc: true,
        handleClick() {
          dispatch(systemActions.setActiveTab({ tab: AnotherPages.contacts }));
        },
      },
      {
        id: 3,
        title: "Написать в чат",
        description: "Техподдержка",
        svg: <IconChat />,
        showArrow: true,
        isFunc: true,
        handleClick() {
          if (isMobile) {
            dispatch(
              systemActions.setActiveTab({ tab: MobileTabVariants.chat })
            );
          } else {
            dispatch(systemActions.setChatOpen(true));
          }
        },
      },
      {
        id: 4,
        title: "Политика конфиденциальности",
        showArrow: true,
        svg: <IconDocument color="#739B67" />,
        isFunc: true,
        handleClick: async () => {
          // TODO заменить на редюсер(не могу настроить)
          // dispatch(getBankDocuments(AgreementType.PrivacyPolicy))
          await downloadPDF("privacy_policy", "Политика конфиденциальности");
        },
      },
      {
        id: 5,
        title: "Правила использования сервиса",
        showArrow: true,
        svg: <IconDocument color="#739B67" />,
        isFunc: true,
        handleClick: async () => {
          // TODO заменить на редюсер(не могу настроить)
          // dispatch(getBankDocuments(AgreementType.TermsOfUse))
          await downloadPDF("terms_of_use", "Правила использования сервиса");
        },
      },
      {
        id: 6,
        title: "Перейти на сайт",
        description: "Подробная информация",
        isFunc: true,
        handleClick: async () => {
          window.open("https://www.cbr.ru/Psystem/field_offices/", "_blank");
        },
        svg: <IconGlobe />,
      },
    ],
    [isMobile, dispatch]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          mb: 40,
          maxWidth: { xs: "initial", md: 545 },
        }}
      >
        {dataIcon?.map(
          ({
            id,
            title,
            svg,
            description,
            handleClick,
            showArrow,
            href,
            isFunc,
          }) => {
            return (
              <a
                key={id}
                href={href}
                style={{
                  textDecoration: "none",
                  display: getDisplayStyle(id, isDesktop),
                }}
              >
                <Box
                  key={id}
                  p={12}
                  display="flex"
                  alignItems="center"
                  columnGap={12}
                  bgcolor="var(--main-color-bg-widgets)"
                  borderRadius="16px"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={isFunc ? handleClick : null}
                >
                  <IconButton
                    sx={{
                      backgroundColor: "var(--main-color-white)",
                      borderRadius: 11,
                      width: isMobile ? 36 : 40,
                      height: isMobile ? 36 : 40,

                      "&:hover": {
                        backgroundColor: "var(--main-color-white)",
                      },
                    }}
                  >
                    {svg}
                  </IconButton>

                  <Box display="flex" flexDirection="column" gap={4}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        color: "var(--main-color-text-title)",
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 400,
                        lineHeight: "14px",
                        color: "var(--main-color-text-secondary)",
                      }}
                    >
                      {description}
                    </Typography>
                  </Box>

                  {showArrow && (
                    <Box sx={{ ml: "auto" }}>
                      <IconChevronRight color="var(--main-color-text-title)" />
                    </Box>
                  )}
                </Box>
              </a>
            );
          }
        )}
      </Box>
    </>
  );
};

export default BankContacts;
