import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IconChat } from "atoms/IconChat/IconChat";
import IconChevron from "atoms/IconChevron/IconChevronLeft";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { IconGlobe } from "atoms/IconGlobe/IconGlobe";
import { IconPhone } from "atoms/IconPhone/IconPhone";
import { useIsMobile } from "hooks/useIsMobile";
import { useMemo, useState } from "react";
import {
  MobileTabVariants,
  TabVariants,
  systemActions,
} from "store/slices/system";
import { ContactsBottomSheet } from "./ContactsBottomSheet/ContactsBottomSheet";
import { useDispatch } from "store";

const Contacts = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();

  const [contactsModal, setContactsModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const data: Array<any> = useMemo(
    () => [
      {
        id: 3,
        title: "Написать в чат",
        description: "Техподдержка",
        svg: <IconChat />,
        handleClick() {
          if (isMobile) {
            dispatch(
              systemActions.setActiveTab({ tab: MobileTabVariants.chat })
            );
          } else {
            dispatch(systemActions.setChatOpen(true));
          }
        },
      },
      {
        id: 0,
        title: "+7 (800) 200-45-67",
        description: "Бесплатный звонок по РФ",
        svg: <IconPhone />,
        handleClick() {
          setPhoneNumber("+7 (800) 200-45-67");
          setContactsModal(true);
        },
      },
      {
        id: 1,
        title: "+7 (812) 603-00-90",
        description: "Бесплатный звонок за пределами РФ",
        svg: <IconPhone />,
        handleClick() {
          setPhoneNumber("+7 (812) 603-00-90");
          setContactsModal(true);
        },
      },
      {
        id: 4,
        title: "Перейти на сайт",
        description: "Подробная информация",
        handleClick() {
          window.open("https://www.cbr.ru/Psystem/field_offices/", "_blank");
        },
        readOnly: true,
        svg: <IconGlobe />,
      },
    ],
    [isMobile, dispatch]
  );

  return (
    <>
      <Box sx={{ gridColumn: "1/-1" }}>
        <Box
          sx={{
            display: "flex",
            padding: "16px 20px 16px 0",
            marginTop: isMobile ? "-28px" : "-4px",
            marginBottom: "12px",
            cursor: "pointer",
          }}
          onClick={() =>
            dispatch(systemActions.setActiveTab({ tab: TabVariants.other }))
          }
        >
          <IconChevron />
          <Typography
            sx={{
              color: "#454A3F",
              textAlign: "center",
              width: "100%",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Связь с банком
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            mb: 40,
          }}
        >
          {data?.map(({ id, title, svg, description, handleClick }) => {
            return (
              <Box
                key={id}
                p={12}
                display="flex"
                alignItems="center"
                columnGap={12}
                bgcolor="var(--main-color-bg-widgets)"
                borderRadius="16px"
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <IconButton
                  sx={{
                    backgroundColor: "var(--main-color-white)",
                    borderRadius: 11,
                    width: isMobile ? 36 : 40,
                    height: isMobile ? 36 : 40,

                    "&:hover": {
                      backgroundColor: "var(--main-color-white)",
                    },
                  }}
                >
                  {svg}
                </IconButton>

                <Box display="flex" flexDirection="column" gap={4}>
                  <Typography
                    sx={{
                      fontSize: "17px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      color: "var(--main-color-text-title)",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "14px",
                      color: "var(--main-color-text-secondary)",
                    }}
                  >
                    {description}
                  </Typography>
                </Box>

                {id === 3 && (
                  <Box sx={{ ml: "auto" }}>
                    <IconChevronRight color="var(--main-color-text-title)" />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      {contactsModal && (
        <ContactsBottomSheet
          isOpen={contactsModal}
          handler={() => setContactsModal(false)}
          phoneNumber={phoneNumber}
        />
      )}
    </>
  );
};

export default Contacts;
