import { RootState } from "store";
import { virtualCardApi } from "api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setStatement } from "./cardsSlice";

type Steps = "initial" | "success" | "error";

export interface VirtualCardState {
  step: Steps;
  isLoading: boolean;
  isOpenVirtualCard: boolean;
  isAvailable: boolean;
}

const initialState: VirtualCardState = {
  step: "initial",
  isLoading: false,
  isOpenVirtualCard: false,
  isAvailable: false,
};

export const checkAvailableVirtualCard = createAsyncThunk(
  "checkAvailableVirtualCard",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await virtualCardApi.virtualCardAvailable("");

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createVirtualCard = createAsyncThunk(
  "createVirtualCard",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const account = (getState() as RootState).cards.account
        .accountNumber as string;

      const { data } = await virtualCardApi.createVirtualCard("", { account });

      dispatch(
        setStatement({
          withCheck: true,
          data: {
            accId: "%",
            forceRefresh: true,
          },
        })
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const virtualCardSlice = createSlice({
  name: "virtualCard",
  initialState,
  reducers: {
    setIsOpenVirtualCard: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenVirtualCard = payload;
    },
    setStep: (state, { payload }: PayloadAction<Steps>) => {
      state.step = payload;
    },
    resetStore: (state) => {
      state.step = "initial";
      state.isLoading = false;
      state.isOpenVirtualCard = false;
      state.isAvailable = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createVirtualCard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createVirtualCard.fulfilled, (state) => {
      state.isLoading = false;
      state.step = "success";
    });
    builder.addCase(createVirtualCard.rejected, (state) => {
      state.isLoading = false;
      state.step = "error";
    });
    builder.addCase(
      checkAvailableVirtualCard.fulfilled,
      (state, { payload }) => {
        state.isAvailable = Boolean(payload.length);
      }
    );
  },
});

export const virtualCardActions = virtualCardSlice.actions;
export const { setStep, setIsOpenVirtualCard } = virtualCardSlice.actions;
