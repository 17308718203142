import { FC, useState } from "react";
import { PushIcon } from "../../atoms/PushIcons/PushIcon";
import { useNavigate } from "react-router-dom";
import { requestPermission } from "../../firebase";
import { handleTogglePush } from "../../store/slices/notifications";
import { showErrorMessage } from "../../store/slices/alerts";
import { PushError } from "pages/profile/ProfileTogglePush";
import { Button } from "components/Button";
import styles from "./styles.module.css";
import { useDispatch } from "store";

export const Push: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceIdItem = localStorage.getItem("PWADeviceId");
  const [isLoading, seiIsLoading] = useState(false);
  const [error, setShowError] = useState(false);

  const displayError = (message: string) => {
    dispatch(
      showErrorMessage({
        errorTitle: "Ошибка",
        errorMessage: message,
      })
    );
  };

  const handlePushToggle = () => {
    seiIsLoading(true);

    requestPermission()
      .then((res) => {
        dispatch(
          handleTogglePush({
            deviceId: deviceIdItem || "",
            fcm: res.token || "",
            enabled: true,
            device_type: "W",
          })
        )
          .unwrap()
          .then(() => {
            localStorage.setItem("isPushOn", "true");
            navigate("/", { replace: true });
          })
          .catch(() => {
            displayError("Проверьте соединение");
          });
      })
      .catch((err) => {
        if (err.alert) {
          setShowError(true);
        } else {
          displayError(err.text);
        }
      })
      .finally(() => {
        seiIsLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <PushIcon />
      <p className={styles.title}>
        Разрешить приложению
        <br />
        отправлять вам push-уведомления?
      </p>
      <p className={styles.description}>
        Вам нужно разрешить отправку
        <br />
        push-уведомлений в системных
        <br />
        настройках устройства.
      </p>
      <Button
        onClick={handlePushToggle}
        variant="primary"
        title="Разрешить"
        isLoading={isLoading}
      />
      <Button
        onClick={() => navigate("/", { replace: true })}
        variant="secondary"
        style={{ marginTop: 10 }}
        title="Не разрешать"
      />
      {error && <PushError setShowError={setShowError} />}
    </div>
  );
};
