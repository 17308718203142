import Navigation from "../Navigation/Navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Hidden } from "@mui/material";
import { AvatarAndName } from "molecules/AvatarAndName/AvatarAndName";
import { NotificationAndNews } from "molecules/NotificationAndNews";
import { Exit } from "molecules/Exit/Exit";
import { MobileTabVariants, systemActions } from "store/slices/system";
import { Logo } from "atoms/Logo";
import { systemSelector, useDispatch, useSelector } from "store";
import { TabVariants } from "store/slices/system";
import { useLocation } from "react-router-dom";
import { useScroll } from "hooks/useScroll";
import { useIsMobile } from "hooks/useIsMobile";

export const Header = () => {
  const dispatch = useDispatch();
  const scrolled = useScroll();
  const location = useLocation();
  const { isMobile } = useIsMobile();
  const { activeTab } = useSelector(systemSelector);

  const logoHandler = () => {
    dispatch(
      systemActions.setActiveTab({
        tab: MobileTabVariants.main,
      })
    );
  };

  const isDesktop = useMediaQuery("(min-width:1024px)");
  let isHeaderVisible = true;

  if (location.pathname === "/" && activeTab !== TabVariants.main) {
    isHeaderVisible = isDesktop ? true : false;
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: isHeaderVisible ? "1000" : "-1",
          visibility: isHeaderVisible ? "visible" : "hidden",
          backgroundColor: scrolled
            ? "rgba(255, 255, 255, 0.76)"
            : "transparent",
          transition: "background-color 0.3s ease",
          backdropFilter: scrolled ? "blur(20px)" : "",

          display: "flex",
          py: { xs: 24, lg: 20 },
          px: { xs: 16, lg: 0 },
          justifyContent: "center",
        }}
      >
        <Box
          maxWidth={1200}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Hidden lgDown>
            <Logo onClick={logoHandler} />
          </Hidden>
          <Hidden lgDown>
            <Navigation />
          </Hidden>
          <Box display="flex" alignItems="center">
            {!isMobile && <NotificationAndNews />}
            <AvatarAndName />
            <Exit />
          </Box>
        </Box>
      </Box>
    </>
  );
};
